import '../../styles/resultSection.css';
import * as React from 'react';
import {StripsContainer} from '../../index';
import {IMonitorListEntry, IMonitorListProps} from "../model/monitor";

export abstract class MonitorList extends React.Component<IMonitorListProps> {

    protected constructor(props: IMonitorListProps) {
        super(props);
    }

    public render() {
        const listNotEmpty: boolean = this.props.monitorEntries && this.props.monitorEntries.length > 0;

        return (
            <div>
                {
                    listNotEmpty &&
                    <StripsContainer>
                        { this.renderList() }
                    </StripsContainer>
                }
            </div>
        );
    }

    protected abstract mapEntry(e: Readonly<IMonitorListEntry>, index: number):  JSX.Element | undefined;

    private renderList = () => {
        return (
            this.props.monitorEntries.map((e:Readonly<IMonitorListEntry>, index)=>{
                return this.mapEntry(e,index);
            })
        );
    }
}