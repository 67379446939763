import {ApiServiceBase} from "../../common/ApiServiceBase";
import {IDropDownItem} from "../../common";
import {IMonitorMessagesApiResult, IRejectedMessageEntry} from "../model/RejectedMessagesModel";
import {IMonitorFilter, IMonitorFilterState} from "../../common/monitor/model/filtering";


export const GET_REJECTED_MESSAGES_URL = '/rejected-messages';
export const GET_REJECTED_SYSTEMS_URL = `${GET_REJECTED_MESSAGES_URL}/rejected-systems`;
export const GET_REJECTED_REASONS_URL = `${GET_REJECTED_MESSAGES_URL}/rejection-types`;
export const GET_ORIGINAL_PAYLOAD_URL =  `${GET_REJECTED_MESSAGES_URL}/file-reference`;


export class MonitorApiService extends ApiServiceBase {

    public getRejectedMessages(query: IMonitorFilter): Promise<IMonitorMessagesApiResult> {
        return this.postData(GET_REJECTED_MESSAGES_URL, null, query)
            .then(response => {
                const result: IMonitorMessagesApiResult = {
                    monitorMessages: [],
                    nextPageExists: false,
                    nextPageToken: ''
                };

                if (response.nextPageToken && response.nextPageToken.length > 0) {
                    result.nextPageToken = response.nextPageToken;
                    result.nextPageExists = true;
                }

                response.data.forEach((message: any) => {
                    const monitorMessage: IRejectedMessageEntry = {
                        Id: message.id,
                        RejectionReason: message.rejectionTypeDisplay,
                        UniqueId: message.uniqueId,
                        CorrelationId: message.correlationId,
                        SystemName: message.system,
                        CreatedDate: message.timestamp,
                        CustomerName: message.customer,
                        Capability: message.system,
                    }
                    result.monitorMessages.push(monitorMessage);
                });

                return new Promise<IMonitorMessagesApiResult>(resolve => resolve(result));
            });
    }

    public getFilterDictionaries(): Promise<IMonitorFilterState> {
        return Promise.all([
            this.getRejectedSystems(),
            this.getRejectionReasons()
        ])
            .then(items => {
                const filterState: IMonitorFilterState = {
                    systemsList: items[0],
                    rejectionReasons: items[1],
                };

                return new Promise<IMonitorFilterState>((resolve) => {
                    resolve(filterState);
                });
            });
    }


    private getRejectedSystems(): Promise<IDropDownItem[]> {
        return this.getDropDownItems(GET_REJECTED_SYSTEMS_URL, {key: '', text: 'All systems'});
    }

    private getRejectionReasons(): Promise<IDropDownItem[]> {
        return this.getDropDownItems(GET_REJECTED_REASONS_URL, { key: '', text: 'All rejection reasons'});
    }
}