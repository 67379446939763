import * as actionTypes from './processDetailsActionTypes';
import { IProcessDetailsView, IProcessDetailsViewAction } from './processDetailsModel';

const initialState: IProcessDetailsView = {
    loading: false,
    errorMessage: '',
    showResendPanel: false,
    item: {
        id: '',
        name: '',
        correlationId: '',
        customer: '',
        originalCorrelationId: '',
        uniqueId: '',
        processState: '',
        steps: [],
        stepNodes: [{
            name: '.',
            attributes: {
            },
            children: []
        }]
    }, 
    showResendUserConfirmationDialog: false,
    showResendConfirmation: false
};

export function processDetailsMainViewReducer (state: IProcessDetailsView = initialState, action: IProcessDetailsViewAction) {
    switch(action.type) {
        case actionTypes.PROCESSDETAILS_LOAD_ITEM_SUCCESS:
            return { ...state, item: action.mainView.item, loading: false }
        
        case actionTypes.PROCESSDETAILS_LOAD_ITEM_ERROR:
            return { ...state, item: {}, loading: false, errorMessage: action.mainView.errorMessage };
        
        case actionTypes.PROCESSDETAILS_LOAD_ITEM_IN_PROGRESS:
            return { ...state, loading: true }

        case actionTypes.PROCESSDETAILS_SHOW_RESENDPANEL:
            return { ...state, showResendPanel: true, showResendConfirmation: false }

        case actionTypes.PROCESSDETAILS_HIDE_RESENDPANEL:
            return { ...state, showResendPanel: false, showResendConfirmation: false }

        case actionTypes.PROCESSDETAILS_RESEND_START:
            return { ...state, showResendUserConfirmationDialog: true, showResendConfirmation: false }

        case actionTypes.PROCESSDETAILS_RESEND_CANCEL:
            return { ...state, showResendUserConfirmationDialog: false, showResendPanel: false, showResendConfirmation: false }
        
        case actionTypes.PROCESSDETAILS_RESEND_PROCESSING:
            return { ...state, showResendUserConfirmationDialog: false, showResendPanel: false, loading: true, showResendConfirmation: false }

        case actionTypes.PROCESSDETAILS_RESEND_END:
            return { ...state, showResendUserConfirmationDialog: false, showResendPanel: false, loading: false, showResendConfirmation: true }
        
        case actionTypes.PROCESSDETAILS_DOWNLOAD_FILE_START:
            return { ...state, loading: true }
        
        case actionTypes.PROCESSDETAILS_DOWNLOAD_FILE_END:
            return { ...state, loading: false }
        
        case actionTypes.PROCESSDETAILS_DOWNLOAD_FILE_ERROR:
            return { ...state, item: {}, loading: false, errorMessage: action.mainView.errorMessage };

        case actionTypes.PROCESSDETAILS_DOWNLOAD_ALL_FILES_START:
            return { ...state, loading: true }
        
        case actionTypes.PROCESSDETAILS_DOWNLOAD_ALL_FILES_END:
            return { ...state, loading: false }
        
        case actionTypes.PROCESSDETAILS_DOWNLOAD_ALL_FILES_ERROR:
            return { ...state, item: {}, loading: false, errorMessage: action.mainView.errorMessage };

        case actionTypes.PROCESSDETAILS_RESEND_PROCESS_START:
            return { ...state, loading: true }
        
        case actionTypes.PROCESSDETAILS_RESEND_PROCESS_END:
            return { ...state, loading: false }
        
        case actionTypes.PROCESSDETAILS_RESEND_PROCESS_ERROR    :
            return { ...state, item: {}, loading: false, errorMessage: action.mainView.errorMessage };
    }

    return state;
}