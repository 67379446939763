import * as React from 'react';
import './styles/filterSection.css';

export interface IFilterSectionProps {
    title?: string;
    children?: any;
    moreFilters?: boolean;
    showDownloadAll: boolean;
    quickFilterTitle?: string;
    quickFilterValue?: string;
    onMoreFilters?: () => void;
    onApply?: () => void;
    onClear?: () => void;
    onChange?: (e: any) => void;
    onDownloadAll?: (e: any) => void;
}

export class FilterSection extends React.Component<IFilterSectionProps, any> {
    constructor(props: IFilterSectionProps) {
        super(props);

        this.state = {
            sectionVisible: false
        };

        this.hideShowFilter = this.hideShowFilter.bind(this);
    }

    public render() {
        const showTitle = this.props.title && this.props.title.length > 0;
        const showDownloadAll = this.props.showDownloadAll;
        const visible = this.props.moreFilters && this.props.onMoreFilters ? this.props.moreFilters :  this.state.sectionVisible;
        const quickFilter = this.props.quickFilterTitle && this.props.quickFilterTitle.length > 0;
        const borderBottom = {
            'borderBottom' : visible ? '' : "none"
        };
        const shown = visible? '' : 'hidden';
        const quickFilterSection = quickFilter ? 
            <div className="simple-filter-section-box">
                <input className="form-control" type="text" placeholder={this.props.quickFilterTitle} name="quickFilter" value={this.props.quickFilterValue} onChange={this.props.onChange} />
                <button className="button button-blue" type="button" onClick={this.props.onApply}>Apply</button>
                <a href="javascript:void(0)" className="regular-link-text show-filter" onClick={this.hideShowFilter}>
                    More filters <i className="fa fa-angle-down fa-lg" aria-hidden="true" />
                </a> 
            </div>
        :   <div>
                <h3> FILTERS </h3>
                <a href="javascript:void(0)" className="regular-link-text show-filter" onClick={this.hideShowFilter}>
                    More filters <i className="fa fa-angle-down fa-lg" aria-hidden="true" />
                </a>
            </div>;

        return (
            <section>
                { showTitle ?
                    <div className="grid-wrapper">
                        <div className="grid-wrapper title">
                            <h3 className="title-blue-underline">{this.props.title}</h3>
                        </div>
                    </div>
                    : ""
                }
                <div className="grid-wrapper">
                    <form className={"filter-search-criteria"}>
                        <fieldset style={borderBottom}>
                            <div className="filter-header">
                                { !visible ? 
                                    quickFilterSection
                                    : <h3> FILTERS </h3>
                                }
                            </div>
                            <div className={shown}>
                                <div className={"grid-wrapper"}>
                                    {this.props.children}
                                </div>
                            </div>
                        </fieldset>
                        <fieldset className={shown}>
                            <div className="filter-button-area">
                                <button className="button button-blue" type="button" onClick={this.props.onApply}>Apply</button>
                                { showDownloadAll ?
                                    <button className="button button-default" style={{ marginRight: '0.5rem' }} type="button" onClick={this.props.onDownloadAll}>Download Payloads</button>
                                    : ""
                                }
                                <a href="javascript:void(0)" className="regular-link-text clear-link" onClick={this.props.onClear}>Clear</a>
                                {
                                    visible ? <a href="javascript:void(0)" className="regular-link-text hide-filter" onClick={this.hideShowFilter}>Less filters <i className="fa fa-angle-up fa-lg" aria-hidden="true" /></a> : ''
                                }
                            </div>
                        </fieldset>
                    </form>
                </div>
            </section>
        );
    }
    
    private hideShowFilter() {

        if (!(this.props.onMoreFilters && this.props.moreFilters !== undefined && this.props.moreFilters !== null)) {
            this.setState({
                sectionVisible: !this.state.sectionVisible
            });
        }
        else {
            this.props.onMoreFilters();
        }
    }
}