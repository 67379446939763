import * as React from 'react';

export interface ICheckboxWithLabelProps {
    labelText?: string;
    name?: string;
    value?: any;
    placeholder?: string;
    onChange?: (e: any) => void;
}

export const CheckboxWithLabel = (props: ICheckboxWithLabelProps) => {
    return (
        <div>
            <input className="fa fa-square-o" type="checkbox" 
                name={props.name} 
                value={props.value} 
                onChange={props.onChange} 
                placeholder={props.placeholder}/>
            &nbsp;<label>{props.labelText}</label>
        </div>
    );
}