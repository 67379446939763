import * as React from "react";
import { Component } from "react";
import {
  PageHeader,
  LoadingAnimation,
  IDropDownItem,
  DropDownFormGroup,
} from "src/common";
import "../groups/styles/groups.css";
import Notifications, { notify } from "react-notify-toast";
import { ICustomer, IProductGroup, IProduct } from "./GroupModels";
import { CustomersTable } from "./CustomersTable";
import { RowInfo } from "react-table";
import { CustomerGroupTable } from "./CustomerGroupTable";
import { SelectedItemDetails } from "./SelectedItemDetails";
import { IRootReducer } from "src/store/rootReducer";
import { bindActionCreators, compose } from "redux";
import * as groupsActions from "./GroupsActions";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { IUserPermissions } from "src/main/main";
import * as userPermissions from "src/common/UserPermissions";

export interface IGroupsContainerState {
  selectedItems: string[];
  productCustomers: ICustomer[];
  productGroups: IProductGroup[];
  selectedGroup?: IProductGroup;
  selectedProductName?: string;
  selectedProduct?: IProduct;
  selectedCustomerGroupTableRow: any;
  detailsModelChanged: boolean;
  detailsVisibility: boolean;
  availableItems: any[];
  detailHeaders: {
    availableHeader: string;
    selectedHeader: string;
  };
  saveDetailsHandler: () => void;
}

export interface IGroupsContainer {
  availableCustomers: ICustomer[];
  availableGroups: IProductGroup[];
  isLoadingData: boolean;
  product: IProduct;
  products: IDropDownItem[];
  userPermissions: IUserPermissions;
  actions: any;
}

export class GroupsContainer extends Component<
  IGroupsContainer,
  IGroupsContainerState
> {
  constructor(props: any) {
    super(props);

    this.state = {
      selectedProductName: "",
      availableItems: [],
      selectedItems: [],
      detailsModelChanged: false,
      detailsVisibility: false,
      selectedCustomerGroupTableRow: { index: -1 },
      selectedGroup: undefined,
      selectedProduct: undefined,
      productCustomers: [],
      productGroups: [],
      detailHeaders: {
        availableHeader: "",
        selectedHeader: "",
      },
      saveDetailsHandler: () => undefined,
    };
  }

  public componentDidMount() {
    this.props.actions.loadProducts();
    this.props.actions.loadAvailableCustomers();
    this.props.actions.loadAvailableGroups();
  }

  public componentWillReceiveProps(props: IGroupsContainer) {
    if (props.product) {
      this.setState({ selectedProduct: props.product });
      this.refreshCustomers(props.product);
      this.refreshGroups(props.product);
    }
  }

  public render() {
    return (
      <>
        <PageHeader title="Groups View" />
        <div className="page-container">
          <div className="grid-wrapper">
            <Notifications />
            {this.props.isLoadingData && <LoadingAnimation />}
            <div className="grid-wrapper">
              {
                <DropDownFormGroup
                  name="products"
                  labelText="Products"
                  formGroupClassName={"col-20"}
                  elements={this.props.products}
                  onChange={this.onProductChanged}
                  value={this.state.selectedProductName}
                />
              }
            </div>
            {this.state.selectedProduct ? (
              <div className="col-70">
                {" "}
                <CustomerGroupTable
                  groups={this.state.productGroups}
                  onRowDeleteClick={this.onCustomerGroupTableDeleteRow}
                  onRowClick={this.onCustomerGroupTableRowClick}
                  onNewCustomerGroupClick={this.onAddGroupClick}
                  onEditCustomerGroupClick={this.onEditGroupsClick}
                  isReadOnly={this.hasPermissions(
                    userPermissions.PERMISSIONS_CONFIGURE_GROUPS
                  )}
                  selectedRowIndex={
                    this.state.selectedCustomerGroupTableRow.index
                  }
                />
                <CustomersTable
                  customers={this.state.productCustomers}
                  onRowDeleteClick={this.onCustomerTableDeleteRow}
                  onEditCustomersClick={this.onEditCustomersClick}
                  isReadOnly={this.hasPermissions(
                    userPermissions.PERMISSIONS_CONFIGURE_GROUPS
                  )}
                />{" "}
              </div>
            ) : null}

            <div className="col-25">
              {this.state.detailsVisibility ? (
                <SelectedItemDetails
                  selectedGroup={this.state.selectedGroup}
                  availableItems={this.state.availableItems}
                  selectedItems={this.state.selectedItems}
                  onCustomerListChanged={this.onSelectionListChanged}
                  onSaveButtonClick={this.state.saveDetailsHandler}
                  onInputValueChange={this.onInputValueChanged}
                  onRowDeleteClick={this.onDeleteGroupClick}
                  modelChanged={this.state.detailsModelChanged}
                  deleteButtonVisibility={
                    this.state.selectedCustomerGroupTableRow.index > -1
                  }
                  headers={this.state.detailHeaders}
                />
              ) : null}
            </div>
          </div>
        </div>
      </>
    );
  }

  public onProductChanged = (val: any) => {
    if (!val.currentTarget.value) {
      return;
    }

    this.props.actions.loadProduct(val.currentTarget.value);
    this.setState({ selectedProductName: val.currentTarget.value });
    this.resetDetailsView();
  };

  public onDeleteGroupClick = () => {
    const productCopy = JSON.parse(JSON.stringify(this.state.selectedProduct));
    if (this.state.selectedCustomerGroupTableRow.index > -1) {
      productCopy.customerGroups.splice(
        this.state.selectedCustomerGroupTableRow.index,
        1
      );
    }
    const groupCopy = JSON.parse(JSON.stringify(this.state.selectedGroup));
    this.props.actions.saveProduct(productCopy).then(() => {
      this.props.actions.deleteGroup(groupCopy).then(() => {
        this.props.actions.loadAvailableGroups();
        notify.show("Group deleted succesfully!", "success");
      });
    });

    this.resetDetailsView();
  };

  public onInputValueChanged = (val: any) => {
    this.setState({ selectedGroup: val });

    const group = JSON.parse(JSON.stringify(this.state.selectedGroup));
    group.name = val;

    this.setState(
      {
        selectedGroup: group,
      },
      () => {
        this.isDetailsStateValid();
      }
    );
  };

  public onCustomerGroupTableRowClick = (val: RowInfo) => {
    if (val.index === -1) {
      return;
    }
    this.setState(
      {
        selectedCustomerGroupTableRow: val,
        selectedItems: val.original.customers,
        availableItems: this.props.availableCustomers.map((x) => x.beCode),
        selectedGroup: val.original,
        saveDetailsHandler: this.onSaveGroupButtonClick,
        detailHeaders: this.getCustomersDetailsHeaders(),
      },
      () => {
        this.showDetails();
      }
    );
  };

  public onEditGroupsClick = (val: any) => {
    this.resetDetailsView();

    this.setState(
      {
        selectedItems: this.props.availableGroups
          .filter(
            (x) =>
              -1 !==
              (this.state.selectedProduct
                ? this.state.selectedProduct.customerGroups.indexOf(x.id)
                : -1)
          )
          .map((y) => y.name),
        availableItems: this.props.availableGroups.map((x) => x.name),
        saveDetailsHandler: this.onSaveProductGroupsButtonClick,
        detailHeaders: this.getGroupsDetailsHeaders(),
      },
      () => {
        this.showDetails();
      }
    );
  };

  public onAddGroupClick = (val: any) => {
    this.resetDetailsView();
    this.setState(
      {
        selectedGroup: { id: "", name: "", customers: [] },
        availableItems: this.props.availableCustomers.map((x) => x.beCode),
        saveDetailsHandler: this.onSaveNewGroupButtonClick,
        detailHeaders: this.getCustomersDetailsHeaders(),
      },
      () => {
        this.showDetails();
      }
    );
  };

  public onEditCustomersClick = (val: any) => {
    this.resetDetailsView();
    this.setState(
      {
        selectedItems: this.state.productCustomers.map((x) => x.beCode),
        availableItems: this.props.availableCustomers.map((x) => x.beCode),
        saveDetailsHandler: this.onSaveProductCustomersButtonClick,
        detailHeaders: this.getCustomersDetailsHeaders(),
      },
      () => {
        this.showDetails();
      }
    );
  };

  public onSaveProductGroupsButtonClick = () => {
    const productCopy = JSON.parse(JSON.stringify(this.state.selectedProduct));
    const groups = this.props.availableGroups
      .filter((x) => -1 !== this.state.selectedItems.indexOf(x.name))
      .map((y) => y.id);

    productCopy.customerGroups = groups;
    this.props.actions.saveProduct(productCopy).then(() => {
      this.resetDetailsView();
      notify.show("Groups updated succesfully!", "success");
    });
  };

  public onSaveProductCustomersButtonClick = () => {
    const productCopy = JSON.parse(JSON.stringify(this.state.selectedProduct));
    productCopy.customers = this.state.selectedItems;
    this.props.actions.saveProduct(productCopy).then(() => {
      this.resetDetailsView();
      notify.show("Product customers updated succesfully!", "success");
    });
  };

  public onSaveNewGroupButtonClick = () => {
    if (!this.state.selectedGroup) {
      return;
    }

    if (!this.state.selectedGroup.name.replace(" ", "")) {
      notify.show("Group name cannot be empty!", "error");
      return;
    }

    const groupCopy = JSON.parse(JSON.stringify(this.state.selectedGroup));
    const productCopy = JSON.parse(JSON.stringify(this.state.selectedProduct));
    const newId: string =
      "new_group_" +
      this.state.selectedGroup.name.replace(" ", "_").toLowerCase();
    groupCopy.id = newId;
    productCopy.customerGroups.push(newId);
    this.setState(
      {
        selectedGroup: groupCopy,
        selectedProduct: productCopy,
      },
      () => {
        this.onSaveGroupButtonClick();
      }
    );
  };

  public onSaveGroupButtonClick = () => {
    const productCopy = JSON.parse(JSON.stringify(this.state.selectedProduct));
    const groupCopy: IProductGroup = JSON.parse(
      JSON.stringify(this.state.selectedGroup)
    );
    groupCopy.customers = this.state.selectedItems;

    this.props.actions
      .saveGroup(groupCopy)
      .then(() => {
        this.props.actions.loadAvailableGroups().then(() => {
          this.props.actions.saveProduct(productCopy).then(() => {
            this.resetDetailsView();
            notify.show("Group saved succesfully!", "success");
          });
        });
      })
      .catch((param: any) => {
        const responseCode = param.split(': ')[1];
        const mainMessage = "Error occurred while trying to save the group.";
        if (responseCode === '409') {
          notify.show(mainMessage +" The group already exists.", "error");
        } else {
          notify.show(mainMessage, "error");
        }
      });
  };

  public onCustomerTableDeleteRow = (val: any) => {
    const productCopy = JSON.parse(JSON.stringify(this.state.selectedProduct));
    const index = productCopy.customers.indexOf(val.original.beCode);
    if (index > -1) {
      productCopy.customers.splice(index, 1);
    }
    this.props.actions.saveProduct(productCopy).then(() => {
      notify.show("Customer removed succesfully!", "success");
    });
    this.resetDetailsView();
  };

  public onCustomerGroupTableDeleteRow = (val: any) => {
    const productCopy = JSON.parse(JSON.stringify(this.state.selectedProduct));
    const index = productCopy.customerGroups.indexOf(val.original.id);
    if (index > -1) {
      productCopy.customerGroups.splice(index, 1);
    }
    this.props.actions.saveProduct(productCopy).then(() => {
      this.props.actions.loadAvailableGroups();
      notify.show("Group removed succesfully!", "success");
    });
    this.resetDetailsView();
  };

  public onSelectionListChanged = (selectedCustomerItems: string[]) => {
    this.setState(
      {
        selectedItems: selectedCustomerItems,
      },
      () => this.isDetailsStateValid()
    );
  };

  private hasPermissions(permission: string): boolean {
    return (
      this.props.userPermissions.businessFunctions.indexOf(permission) !== -1
    );
  }

  private resetDetailsView() {
    this.setState({
      selectedCustomerGroupTableRow: -1,
      selectedItems: [],
      detailsVisibility: false,
      detailsModelChanged: false,
      selectedGroup: undefined,
    });
  }

  private showDetails() {
    this.setState({
      detailsVisibility: this.hasPermissions(
        userPermissions.PERMISSIONS_CONFIGURE_GROUPS
      ),
    });
  }

  private refreshCustomers(product: IProduct) {
    const customers: ICustomer[] = [];
    product.customers.forEach((prodCustm: string) => {
      const cust = this.props.availableCustomers.find(
        (y) => y.beCode === prodCustm
      );
      if (cust !== undefined) {
        customers.push(cust);
      }
    });

    this.setState({
      productCustomers: customers,
    });
  }

  private refreshGroups(product: IProduct) {
    const groups: IProductGroup[] = [];
    product.customerGroups.forEach((prodGroup: string) => {
      const group = this.props.availableGroups.find((y) => y.id === prodGroup);
      if (group) {
        groups.push(group);
      }
    });
    this.setState({
      productGroups: groups,
    });
  }

  private validateName(value: string) {
    const patt = new RegExp(/^[0-9a-zA-Z \b]+$/);
    return patt.test(value);
  }

  private isDetailsStateValid() {
    let validationResult = true;
    if (this.state.selectedGroup) {
      const id = this.state.selectedGroup.id;
      const group = this.props.availableGroups.find((x) => x.id === id);
      if (group) {
        validationResult =
          this.validateName(this.state.selectedGroup.name) &&
          (group.name !== this.state.selectedGroup.name 
            || group.customers.length !== this.state.selectedItems.length
            || group.customers.some(item => this.state.selectedItems.indexOf(item) === -1)
            || this.state.selectedItems.some(item => group.customers.indexOf(item) === -1)
          );
      }
    }
    this.setState({
      detailsModelChanged:
        this.state.selectedItems.length > 0 && validationResult,
    });
  }

  private getGroupsDetailsHeaders() {
    return {
      availableHeader: "Available groups",
      selectedHeader: "Selected groups",
    };
  }

  private getCustomersDetailsHeaders() {
    return {
      availableHeader: "Available customers",
      selectedHeader: "Selected customers",
    };
  }
}

const mapStateToProps = (state: IRootReducer, ownProps: any) => {
  return {
    products: state.groupsView.products,
    product: state.groupsView.product,
    availableCustomers: state.groupsView.availableCustomers,
    availableGroups: state.groupsView.availableGroups,
    userPermissions: state.app.userPermissions,
    isLoadingData: state.groupsView.isLoadingData,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    actions: bindActionCreators(groupsActions, dispatch),
  };
};

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(GroupsContainer);
