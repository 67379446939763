import * as React from "react";
import {IListBoxItem} from "./IListBoxItem";
import {ListBox} from "./listbox";
import "./DualListBox.css";
import {Button, ButtonType} from "../button";

interface IDualListBoxProps {
    leftTitle: string;
    rightTitle: string;
    options: IListBoxItem[];
    selected: IListBoxItem[];
    height: number;
    sort?: boolean;
    readonly?: boolean;
    leftUsesTemplate?: boolean;
    rightUsesTemplate?: boolean;
    onActivate?: (selected: IListBoxItem[], allSelected: IListBoxItem[]) => void;
    onDeactivate?: (deselected: IListBoxItem[], allSelected: IListBoxItem[]) => void;
    onApply?: (selected: IListBoxItem[], deselected: IListBoxItem[]) => void;
}

interface IDualListBoxState {
    itemsInLeftBox: IListBoxItem[];
    selectedItemsInLeftBox: IListBoxItem[];
    itemsInRightBox: IListBoxItem[];
    selectedItemsInRightBox: IListBoxItem[];
    buttonDisabled: boolean;
}

export class DualListBox extends React.Component<IDualListBoxProps, IDualListBoxState> {
    constructor(props: IDualListBoxProps) {
        super(props);
        this.state = this.buildState(this.props);
        this.activate = this.activate.bind(this);
        this.preventDoubleClick = this.preventDoubleClick.bind(this);
        this.deactivate = this.deactivate.bind(this);
        this.updateLeftBoxSelection = this.updateLeftBoxSelection.bind(this);
        this.updateRightBoxSelection = this.updateRightBoxSelection.bind(this);
        this.onApply = this.onApply.bind(this);
    }

    public componentDidUpdate(prevProps: Readonly<IDualListBoxProps>) {
        if (this.props.options !== prevProps.options) {
            this.setState(this.buildState(this.props));
        }
    }

    public render() {
        return (
            <div className={"dual-listbox__container"}>
                <ListBox
                    title={this.props.leftTitle}
                    options={this.state.itemsInLeftBox}
                    selected={this.state.selectedItemsInLeftBox}
                    sort={this.props.sort}
                    readonly={this.props.readonly}
                    onSelect={this.updateLeftBoxSelection} maxHeight={this.props.height}
                    children={this.props.leftUsesTemplate ? this.props.children : undefined}
                />
                <div className={"dual-listbox__button-container"}>
                    <Button buttonType={ButtonType.Blue} onClick={this.activate} isDisabled={this.props.readonly}> {'>'} </Button>
                    <Button buttonType={ButtonType.Blue} onClick={this.deactivate} isDisabled={this.props.readonly}> {'<'} </Button>
                    {this.renderApplyButton()}
                </div>
                <ListBox
                    title={this.props.rightTitle}
                    options={this.state.itemsInRightBox}
                    selected={this.state.selectedItemsInRightBox}
                    sort={this.props.sort}
                    readonly={this.props.readonly}
                    onSelect={this.updateRightBoxSelection} maxHeight={this.props.height}
                    children={this.props.rightUsesTemplate ? this.props.children : undefined}
                />
            </div>
        );
    }

    private renderApplyButton(): JSX.Element {
        if(!this.props.onApply || this.props.readonly) {
            return <></>
        }
        return (
            <Button buttonType={ButtonType.Blue} onClick={this.onApply} isDisabled={this.state.buttonDisabled}> Apply </Button>
        )
    }

    private buildState(props: IDualListBoxProps): IDualListBoxState {
        return {
            itemsInRightBox: props.options.filter(option => props.selected.some(s => s.key === option.key)),
            itemsInLeftBox: props.options.filter(option => props.selected.every(s => s.key !== option.key)),
            selectedItemsInLeftBox: [],
            selectedItemsInRightBox: [],
            buttonDisabled: false
        }
    }

    private clearSelected(afterClear: () => void) {
        this.setState({selectedItemsInLeftBox: []}, () => {
            this.setState({selectedItemsInRightBox: []}, afterClear);
        });
    }

    private setInactive(newActiveItems: IListBoxItem[], selected: IListBoxItem[]) {
        const newInactiveItems = this.state.itemsInLeftBox.filter(item => newActiveItems.indexOf(item) === -1);
        this.setState({itemsInLeftBox: newInactiveItems}, () =>
            this.props.onActivate && this.props.onActivate(selected, this.state.itemsInRightBox)
        );
    }

    private setActive(newInactiveItems: IListBoxItem[], selected: IListBoxItem[]) {
        const newActiveItems = this.state.itemsInRightBox.filter(item => newInactiveItems.indexOf(item) === -1);
        this.setState({itemsInRightBox: newActiveItems}, () =>
            this.props.onDeactivate && this.props.onDeactivate(selected, this.state.itemsInRightBox)
        );
    }

    private onApply(): void {
        if (!this.props.onApply) {
            return;
        }
        this.preventDoubleClick();
        this.props.onApply(this.state.itemsInRightBox, this.state.itemsInLeftBox);
    }

    private activate(): void {
        const newActiveItems = this.state.itemsInRightBox.concat(this.state.selectedItemsInLeftBox);
        const selected = this.state.selectedItemsInLeftBox;
        this.clearSelected(() => this.setState({itemsInRightBox: newActiveItems},
            () => this.setInactive(newActiveItems, selected)
            )
        );
    }

    private deactivate(): void {
        const newInactiveItems = this.state.itemsInLeftBox.concat(this.state.selectedItemsInRightBox);
        const selected = this.state.selectedItemsInRightBox;
        this.clearSelected(() => this.setState({itemsInLeftBox: newInactiveItems},
            () => this.setActive(newInactiveItems, selected)
        ));
    }

    private updateLeftBoxSelection(selectedItems: IListBoxItem[]) {
        this.setState({selectedItemsInLeftBox: selectedItems});
    }

    private updateRightBoxSelection(selectedItems: IListBoxItem[]) {
        this.setState({selectedItemsInRightBox: selectedItems});
    }

    private preventDoubleClick() {
        this.setState({buttonDisabled: true},
            () => setTimeout( () =>  this.setState({buttonDisabled: false}), 3000)
        )
    }
}