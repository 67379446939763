import {bindActionCreators, compose} from "redux";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import * as React from "react";
import {IRootReducer} from "../../store/rootReducer";
import * as configurationActions from "../store/CustomerGroup/Actions";
import {LoadingAnimation, PageHeader} from "src/common";
import Notifications from "react-notify-toast";
import CustomerGroupSelector from "../components/CustomerGroupSelector";
import {ICustomerGroupConfigurationState} from "../store/CustomerGroup";
import ProcessConfiguration from "../components/ProcessConfiguration";
import {ICustomerProcessDefinition} from "../models/ICustomerProcessDefinition";
import CapabilitiesList from "../components/CapabilitiesList";
import {filterCapabilities} from "../helpers";
import {ICustomerGroup, IProcessDefinition, ICapability} from "src/api/models";
import BeCodeAssigner from "../components/BeCodeAssigner";
import Accordion from "../../common/Accordion";
import {PERMISSIONS_CONFIGURE_GROUPS} from "../../common/UserPermissions";


interface IGroupConfigurationContainerProps {
    actions: typeof configurationActions;
    capabilities: ICapability[];
    selectedProcess: IProcessDefinition;
    customerGroup: ICustomerGroup;
    isLoading: boolean;
}

class CustomerGroupConfiguration extends React.Component<IGroupConfigurationContainerProps> {
    constructor(props: IGroupConfigurationContainerProps) {
        super(props);
        this.onProcessClick = this.onProcessClick.bind(this);
        this.onSaveChanges = this.onSaveChanges.bind(this);
    }

    public render() {
        return (
            <>
                <PageHeader title="Customer Groups Details"/>
                <div className="page-container">
                    <div className="grid-wrapper">
                        <Notifications/>
                        {this.props.isLoading && <LoadingAnimation/>}
                        <CustomerGroupSelector/>
                        <BeCodeAssigner editPermissions={PERMISSIONS_CONFIGURE_GROUPS}/>
                        <Accordion title={"Process Configuration"}>
                            <div className="grid-wrapper">
                                <div className="col-30">
                                    <CapabilitiesList
                                        owner={this.props.customerGroup}
                                        onProcessClicked={this.onProcessClick}
                                        capabilities={this.filterCapabilities()}/>
                                </div>
                                <div className="col-70">
                                    <ProcessConfiguration
                                        onSaveChanges={this.onSaveChanges}
                                        owner={this.props.customerGroup}
                                        processDefinition={this.props.selectedProcess}
                                    />
                                </div>
                            </div>
                        </Accordion>
                    </div>
                </div>
            </>
        )
    }

    public componentDidMount() {
        this.props.actions.loadCustomers();
        this.props.actions.loadCapabilities();
    }

    private onProcessClick(processName: string): void {
        this.props.actions.loadProcessDefinition(processName);
    }

    private filterCapabilities(): ICapability[] {
        const customerGroup = this.props.customerGroup;
        if (!this.props.capabilities || customerGroup == null || customerGroup.processIds == null) {
            return [];
        }
        return filterCapabilities(this.props.capabilities, customerGroup.processIds);
    }

    private onSaveChanges(processDefinition: ICustomerProcessDefinition) {
        if (this.props.customerGroup == null) {
            return;
        }
        const customer = this.props.customerGroup as ICustomerGroup;

        const processIds = customer.processIds?.map(process => {
            if (processDefinition?.name === process.id) {
                return {...process, enabled: processDefinition.enabled}
            } else {
                return process
            }
        })

        const steps = customer.steps?.map(step => {
            const processStep = processDefinition?.steps.find((s: any) => s.messageType === step.id)
            if (processStep !== undefined) {
                return {...step, enabled: processStep.enabled}
            } else {
                return step
            }
        })
        if (!processIds || !steps) {
            return;
        }

        this.props.actions
            .saveCustomerGroup({...customer, processIds, steps})

    }

}


const mapStateToProps = (state: IRootReducer) => {
    const localState: ICustomerGroupConfigurationState = state.groupConfigurationView;
    return {
        isLoading: localState.isLoading,
        capabilities: localState.capabilities,
        customerGroup: localState.selectedCustomerGroup,
        selectedProcess: localState.selectedProcessDefinition
    }
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        actions: bindActionCreators(configurationActions, dispatch)
    }
}

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(CustomerGroupConfiguration);