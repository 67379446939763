import * as actionTypes from './mainActionTypes'
import { IApp, IUserPermissions } from './main';

export const mainReducer = (state: IApp = {
    isLoading: true,
    errorLoading: false,
    userPermissions: {} as IUserPermissions
} as IApp, action: any): IApp => {

    switch (action.type) {
        case actionTypes.MAIN_LOAD_IN_PROGRESS:
            return { ...state, isLoading: true };

        case actionTypes.MAIN_LOAD_ERROR:
            return { ...state, errorLoading: false };

        case actionTypes.MAIN_LOAD_USERPERMISSIONS_SUCCESS:
            return { ...state, isLoading: false, errorLoading: false, userPermissions: action.result };
    }

    return { ...state };
}