import * as React from 'react';
import * as moment from "moment";
import {IMonitorContainer, IMonitorContainerState} from "../model/monitor";
import {IMonitorFilterState} from "../model/filtering";

export abstract class MonitorContainer<T extends IMonitorContainer, S extends IMonitorContainerState>
    extends React.Component<IMonitorContainer,IMonitorContainerState> {
    protected path: string;
    protected detailsPath:string;
    protected constructor(props: IMonitorContainer) {
        super(props);

        this.state = {
            intervalId: null
        };

        this.onItemSelect = this.onItemSelect.bind(this);
        this.onFilterApply = this.onFilterApply.bind(this);
        this.onFilterChange = this.onFilterChange.bind(this);
        this.onFilterClear = this.onFilterClear.bind(this);

        this.onMonitorMoreFiltersChanged = this.onMonitorMoreFiltersChanged.bind(this);
    }

    public componentDidMount() {
        this.loadFilterDictionaries();
    }

    public componentWillUnmount() {
        if (this.state.intervalId) {
            clearInterval(this.state.intervalId);
        }
    }

    protected abstract loadFilterDictionaries(): void;

    protected abstract createEmptyFilterState(): Partial<IMonitorFilterState>;

    protected abstract loadItems(): void;

    protected onItemSelect (id: string): void {
        this.props.history.push(this.detailsPath + id);
    }

    protected updateFilters(newState: Partial<IMonitorFilterState>, callback?: () => void) {
        this.props.actions.changeFilterState({...this.props.filter, ...newState});
        if (callback) {
            callback();
        }
    }

    protected onFilterApply() {
        this.updateFilters({NextPageToken: ''}, () => {
            this.loadItems();
        });
    }

    protected onFilterClear() {
        this.updateFilters(this.createEmptyFilterState());
        this.clearHistoryState();
    }

    protected onFilterChange(e: any) {
        let propertyName = e.target.name;
        if (propertyName === 'quickFilter'){
            propertyName = 'correlationId';
        }

        const changedFilter = { };

        if (propertyName === "DateFrom" || propertyName === "DateTo") {
            if (typeof e.target.value === "string") {
                changedFilter[propertyName] = moment(e.target.value);
            }
        }
        else if (propertyName === "autoRefresh") {
            if(e.target.checked){
                const id = setInterval(this.onFilterApply, 60000);
                this.setState(
                    {
                        intervalId: id
                    });
            }
            else{
                clearInterval(this.state.intervalId);
                this.setState({intervalId: null});
            }
        }
        else {
            changedFilter[propertyName] = e.target.value;
        }

        this.updateFilters(changedFilter);
        this.clearHistoryState();
    }

    protected clearHistoryState() {
        this.props.history.replace(this.path, null);
    }

    protected onMonitorMoreFiltersChanged() {
        if (this.props.monitorMoreFilters){
            this.updateFilters(this.createEmptyFilterState());
        }
        this.props.actions.changeMoreFiltersOnProcesses(this.props.monitorMoreFilters);
    }
}