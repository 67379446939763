import * as React from 'react';
import { generalHeaderStyles } from './utils/columnStyles';

export const header = (rowInfo: any, headerTxt: string) => {
    return (
        <div style={generalHeaderStyles}>
            <span>{headerTxt}</span>
            <i className="fa fa-sort" aria-hidden="true"/>
        </div>
    );
}