export interface IMessageDetailsItem {
    id: string;
    status:string;
    messageType: string;
    correlationId: string;
    uniqueId: string;
    systemName: string;
    createdDate: string;
    customer: string;
    monitorMessage: string;
}

export const MessageDetailsItemLabel: IMessageDetailsItem = {
    id: 'ID',
    status: 'Message Status',
    messageType: 'Message-Type',
    correlationId: 'Correlation-ID',
    uniqueId: 'Unique-ID',
    systemName: 'System Name',
    createdDate: 'Created',
    customer: 'Customer',
    monitorMessage: 'Message from Monitor',
}

export interface IMessageDetailsContainerState {
    loading: boolean;
    data: IMessageDetailsItem;
    originalPayload: string;
    errorMessage?: string;
}
export interface IMessageDetailsContainerAction {
    type: string;
    view: IMessageDetailsContainerState;
}

export interface IMessageDetailsContainerProps {
    loading: boolean;
    data: IMessageDetailsItem;
    actions: any;
    match: any;
    originalPayload: string;
}

export interface IOriginalPayloadQuery {
    Path: string;
    System: string;
}