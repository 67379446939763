import * as React from 'react';
import { Column } from 'react-table';
import { ColumnTheme, setStyles } from "../data-table/utils/columnStyles";
import { header } from './Header';

export const BooleanColumn = (
    accessor: string,
    name: string,
    trueValue: string,
    falseValue: string,
    config: {
        width?: number,
        theme?: ColumnTheme,
        resizable?: boolean,

    } = {}
): Column => {
    return {
        headerClassName: 'data-table header',
        show: true,
        accessor,
        resizable: config.resizable,
        width: config.width,
        style: {
            cursor: 'pointer',
            ...setStyles(config.theme)
        },
        Header: (rowInfo: any) => header(rowInfo, name),
        Cell: (rowInfo) => (
            <div>{rowInfo.value ? trueValue : falseValue}</div>
        )
    };
};