import { ApiServiceBase } from "../common/ApiServiceBase"
import { IDropDownItem } from "../common"
import { IUserActivityFilterState } from "./UserActivityContainer"
import { IUserActivityFilter as IUserActivityFilter, IActivityQuery, IActivity } from "./UserActivityApi"

const GET_CAPABILITIES_URL = "/capabilities"
const GET_CUSTOMERS = "/customers"
const GET_PROCESSES_URL = "/processes"
const GET_USER_ACTIVITY_TYPES_URL = "users/activity-types"
const GET_USERS_ACTIONS_URL = "users/activity-history"

export interface IActivityResult {
	activities: IActivity[]
}

export class UserActivityApiService extends ApiServiceBase {
	public getFilterDictionaries(): Promise<IUserActivityFilterState> {
		return Promise.all([this.getCapabilities(), this.getProcesses(), this.getCustomers(), this.getActivityTypes()]).then(
			items => {
				const filterState: IUserActivityFilterState = {
					capabilities: items[0],
					processes: items[1],
					customers: items[2],
					activityTypes: items[3]
				}

				return new Promise<IUserActivityFilterState>(resolve => {
					resolve(filterState)
				})
			}
		)
	}

	public getUsersActions(filter: IUserActivityFilter): Promise<IActivityResult> {
		const processQuery = this.filterToQueryParameter(filter)

		return this.postData(GET_USERS_ACTIONS_URL, null, processQuery).then(response => {
			const result: IActivityResult = {
				activities: []
			}

			response.forEach((activities: any) => {
				result.activities.push({
					Id: activities.id,
					UniqueId: activities.uniqueId,
					CorrelationId: activities.correlationId,
					OriginalCorrelationId: activities.originalCorrelationId,
					Process: activities.process,
					DateTime: activities.dateTime,
					Customer: activities.customer,
					Capability: activities.capability,
					UserName: activities.userName,
					UserEmail: activities.userEmail,
					Comments: activities.comments,
					ActivityType: activities.activityType,
					ActivityTypeDisplay: activities.activityTypeDisplay,
					System: activities.system,
					MessageType: activities.messageType,
				})
			})

			return new Promise<IActivityResult>(resolve => resolve(result))
		})
	}

	private getCapabilities(): Promise<IDropDownItem[]> {
		return this.getDropDownItems(GET_CAPABILITIES_URL, { key: "", text: "All capabilities" })
	}

	private getProcesses(): Promise<IDropDownItem[]> {
		return this.getDropDownItems(GET_PROCESSES_URL, { key: "", text: "All processes" })
	}

	private getCustomers(): Promise<IDropDownItem[]> {
		return this.getDropDownItems(GET_CUSTOMERS, { key: "", text: "All customers" })
	}

	private getActivityTypes(): Promise<IDropDownItem[]> {
		return this.getDropDownItems(GET_USER_ACTIVITY_TYPES_URL, { key: "", text: "All types" })
	}

	private filterToQueryParameter(filter: IUserActivityFilter): IActivityQuery {
		const activityQuery: IActivityQuery = {
			CorrelationId: filter.correlationId,
			UniqueId: filter.uniqueId,
			Capability: filter.capability,
			ProcessName: filter.process,
			Customer: filter.customer,
			From: filter.dateFrom ? filter.dateFrom.toISOString() : "",
			To: filter.dateTo ? filter.dateTo.toISOString() : "",
			UserName: filter.userName,
			UserEmail: filter.userEmail,
			ActivityType: filter.activityType
		}

		return activityQuery
	}
}
