import * as actionTypes from './dashboardActionTypes';
import { IDashboardContainer } from './DashboardContainer';

export const dashboardMainViewReducer = (state: IDashboardContainer, action: any): IDashboardContainer => {
    switch(action.type) {
        case actionTypes.OPERATIONAL_LOAD_IN_PROGRESS:
            return { ...state, isLoadingCharts: true, isLoadingCapabilityLinks: true };

        case actionTypes.OPERATIONAL_LOAD_ERROR:
            return { ...state, errorLoading: true };

        case actionTypes.DASHBOARD_LOAD_CAPABILITIES_CHARTS_SUCCESS:
            return { ...state, isLoadingCharts: false, capabilityCharts: action.systemCharts };

        case actionTypes.DASHBOARD_LOAD_CAPABILITIES_LINKS_SUCCESS:
            return { ...state, isLoadingCapabilityLinks: false, capabilityLinks: action.systemLinks };
    }

    return { ...state, isLoadingCharts: true, isLoadingCapabilityLinks: true };
}