import * as React from "react"
import { BrowserRouter as Router } from "react-router-dom"
import { RoutingConfiguration } from "./RoutingConfiguration"
import { NavLinks } from "./NavLinks"
import "./main.css"
import { LoadingAnimation } from "src/common"
import { compose, bindActionCreators } from "redux"
import { connect } from "react-redux"
import { IRootReducer } from "src/store/rootReducer"
import * as mainActions from "./mainActions"
import { GlobalHistory } from "../common/history"
import authentication from "../common/Authentication"
import { UnauthorizedRoutingConfiguration } from "./UnauthorizedRoutingConfiguration"
import { AdvancedError } from "../error/AdvancedError"
import StandaloneErrorContainer from "../error/StandaloneErrorContainer";
import {NO_PERMISSIONS_ERROR} from "../error/CustomErrorTypes";

export interface IApp {
	isLoading: boolean
	errorLoading: boolean
	userPermissions: IUserPermissions
	actions: any
}

export interface IUserPermissions {
	businessFunctions: string[]
	roles: string[]
}

class App extends React.Component<IApp, any> {
	constructor(props: IApp) {
		super(props)
	}

	public componentDidMount() {
		if (authentication.isSuccess()) {
			this.props.actions.loadUserPermissions()
		}
	}

	public render() {
		const arePermissionLoaded = this.props.isLoading
		const hasAnyPermissions =
		!!this.props.userPermissions.businessFunctions && !!this.props.userPermissions.roles &&
		this.props.userPermissions.businessFunctions.length > 0  && this.props.userPermissions.roles.length > 0;


		if (authentication.isSuccess() && arePermissionLoaded) {
			return <LoadingAnimation />
		} else {
			if (!hasAnyPermissions) {
				const advancedError = AdvancedError.FromError(NO_PERMISSIONS_ERROR);
				return (<StandaloneErrorContainer error={advancedError}/>);
			}
			else {
				return (
					<Router>
						<div className="lsg-content">
							<GlobalHistory/>
							<header className="ds-header app-logged-in">
								<NavLinks showNavigation={hasAnyPermissions}
								userPermissions={this.props.userPermissions}/>
							</header>
							{authentication.isSuccess() && hasAnyPermissions ? <RoutingConfiguration/> :
								<UnauthorizedRoutingConfiguration/>}
						</div>
					</Router>
				)
			}
		}
	}
}

const mapStateToProps = (state: IRootReducer, ownProps: any) => {
	return {
		isLoading: state.app.isLoading,
		userPermissions: state.app.userPermissions
	}
}

const mapDispatchToProps = (dispatch: any) => {
	return {
		actions: bindActionCreators(mainActions, dispatch)
	}
}

export default compose(
	connect(
		mapStateToProps,
		mapDispatchToProps
	)
)(App)
