import * as React from 'react';

export interface IAccordionProps{
    title: string;
    toggleClassName?: string;
    titleClassName?: string;
    isExpanded?: boolean;
}

interface IAccordionState{
    isExpanded: boolean;
}

class Accordion extends React.Component<IAccordionProps, IAccordionState>{
    constructor(props: IAccordionProps){
        super(props);
        this.state = {
          isExpanded: props.isExpanded ? props.isExpanded : false
        };
    }

    public componentDidMount(){
        this.state = {
            isExpanded: this.props.isExpanded ? this.props.isExpanded : false
        };
    }

    public toggleAccordion = () => {
      this.setState({
          isExpanded: !this.state.isExpanded
      });
    };

    public render(){
        return(
          <div className={"accordion-wrapper"}>
              <div className={"accordion-title " + this.props.toggleClassName} onClick={this.toggleAccordion}>
                  <h3 className={"title-blue-underline " + this.props.titleClassName}>{this.props.title}</h3>
                  {
                      (this.state.isExpanded) ?
                          <i className="fa fa-chevron-up" aria-hidden="true"/>
                      :
                          <i className="fa fa-chevron-down" aria-hidden="true"/>
                  }
              </div>
              <div className={"accordion-body"}>
                  {
                      (this.state.isExpanded) ?
                          this.props.children
                      :
                          ""
                  }
              </div>
          </div>
        );
    }
}

export default Accordion;