import * as actionTypes from "./userActivityActionsTypes"
import { IUserActivityContainer } from "./UserActivityContainer"
import * as moment from "moment"

export const userActivityReducer = (
	state: IUserActivityContainer = {
		processesMoreFilters: true,
		filter: {
			userName: "",
			userEmail: "",
			correlationId: "",
			uniqueId: "",
			process: "",
			capability: "",
			customer: "",
			dateFrom: moment()
				.startOf("day")
				.add(-1, "week"),
			dateTo: moment()
				.startOf("hour")
				.add(1, "hour"),
			activityType: ""
		}
	} as IUserActivityContainer,
	action: any
): IUserActivityContainer => {
	switch (action.type) {
		case actionTypes.USERACTIVITY_LOAD_IN_PROGRESS:
			return { ...state, isLoading: true }

		case actionTypes.USERACTIVITY_LOAD_ERROR:
			return { ...state, errorLoading: true }

		case actionTypes.USERACTIVITY_LOAD_PROCESSES_SUCCESS:
			return { ...state, isLoading: false, activities: action.result.activities }

		case actionTypes.USERACTIVITY_LOAD_FILTER_DICTIONARIES:
			return { ...state, isLoading: false, filter: { ...state.filter, ...action.filter } }

		case actionTypes.USERACTIVITY_CLEAR_RESULTS:
			return { ...state, activities: [] }

		case actionTypes.USERACTIVITY_CHANGE_PROCESSES_MORE_FILTERS:
			return { ...state, processesMoreFilters: !action.currentMoreFilters }

		case actionTypes.USERACTIVITY_CHANGE_FILTERS:
			return { ...state, filter: { ...state.filter, ...action.newFilters } }
	}

	return { ...state }
}
