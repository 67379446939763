import * as React from 'react';

interface IStatusProps {
    status?: string;
}

export const StatusItem = (props: IStatusProps) => {

    let statusIcon = "";
    const spanStyle = {
        color: '#54AB42'
    };
    switch(props.status){
        case "New":
            statusIcon = "fa fa-bolt";
            spanStyle.color = "#398FD1";
            break;
        case "Running":
            statusIcon = "fa fa-rocket";
            spanStyle.color = "#CE8E00";
            break;
        case "Completed":
            statusIcon = "fa fa-thumbs-up";
            spanStyle.color = "#54AB42";
            break;
        case "Failed":
            statusIcon = "fa fa-thumbs-down";
            spanStyle.color = "#D52B1E";
            break;
    }

    return(
        <span style={spanStyle}><i className={statusIcon} />&nbsp;{props.status}</span>
    );
}