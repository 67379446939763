export const MONITOR_LOAD_IN_PROGRESS = 'MONITOR_LOAD_IN_PROGRESS';
export const MONITOR_LOAD_ERROR = 'MONITOR_LOAD_ERROR';
export const MONITOR_LOAD_REJECTED_MESSAGES_SUCCESS = 'MONITOR_LOAD_PROCESSES_SUCCESS';
export const MONITOR_LOAD_REJECTED_MESSAGES_NEXT_PAGE_SUCCESS = 'MONITOR_LOAD_PROCESSES_NEXT_PAGE_SUCCESS';
export const MONITOR_LOAD_FILTER_DICTIONARIES = 'MONITOR_LOAD_FILTER_DICTIONARIES';
export const MONITOR_CLEAR_RESULTS = 'MONITOR_CLEAR_RESULTS';
export const MONITOR_CHANGE_FILTER_TO_PROCESSES = 'MONITOR_CHANGE_FILTER_TO_PROCESSES';
export const MONITOR_CHANGE_PROCESSES_MORE_FILTERS = 'MONITOR_CHANGE_PROCESSES_MORE_FILTERS';
export const MONITOR_CHANGE_FILTERS = 'MONITOR_CHANGE_FILTERS';


