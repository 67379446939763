import * as React from "react"
import {ResultItem} from "../../common/ResultItem";

interface IRejectionReason {
    displayName?: string
}

export const RejectedMessagesReasonItem = (props: IRejectionReason) => {
    const spanStyle = {
        color: "#64B2D4"
    }
    spanStyle.color = "#64B2D4"
    return (
        <span style={spanStyle}>
            <ResultItem title="Rejection Reason" value={props.displayName} className=""/>
		</span>
    )
}
