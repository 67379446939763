import {IAdvancedError} from './IAdvancedError';
import {IAuthError} from "../common/AuthenticationModel";

export const UNKNOWN_ERROR: IAuthError = {
    name: "UNKNOWN ERROR",
    errorCode: "UNKNOWN",
    message: "Unknown error occured. Please report this accident to support including details of error."
};

export const INCORRECT_TENANT_ERROR: IAuthError = {
    name: "INCORRECT TENANT",
    errorCode: "AADSTS90072",
    message: "You have tried to login using unauthorized tenant. Please login using your Maersk Account."
};

export const ADD_USER_TO_AAD_GROUP_ERROR: IAuthError = {
    name: "USER NOT ASSIGNED TO AAD GROUP",
    errorCode: "AADSTS50105",
    message: "You are not allowed to login. Please, contact Brain team and ask to be added to AAD Login Group."
}

export const NO_PERMISSIONS_ERROR: IAdvancedError = {
    name: "YOU DO NOT HAVE PERMISSIONS TO VIEW BRAIN UI",
    message: "Please, use SNOW to raise ticket to UAM team with request to get access"
};

export const CLEAR_CACHE_AND_RETRY: IAdvancedError = {
    name: "SOMETHING GOES WRONG",
    message: "Clear your browser cache and try again. If problem continues to appear, then contact with Brain team."
}

export const SUPPORTED_ERROR_TYPES = [
    INCORRECT_TENANT_ERROR,
    ADD_USER_TO_AAD_GROUP_ERROR
];