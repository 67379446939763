import { IUserActivityFilter } from "./UserActivityApi"
import * as actionTypes from "./userActivityActionsTypes"
import { IUserActivityFilterState } from "./UserActivityContainer"
import { UserActivityApiService, IActivityResult } from "./UserActivityApiService"

export const loadError = () => {
	return { type: actionTypes.USERACTIVITY_LOAD_ERROR, result: { errorLoading: true } }
}

export const loadInProgress = () => {
	return { type: actionTypes.USERACTIVITY_LOAD_IN_PROGRESS }
}

export const loadActivitiesSuccess = (result: IActivityResult) => {
	return { type: actionTypes.USERACTIVITY_LOAD_PROCESSES_SUCCESS, result }
}

export const loadFilterDictionariesSuccess = (filter: IUserActivityFilterState) => {
	return { type: actionTypes.USERACTIVITY_LOAD_FILTER_DICTIONARIES, filter }
}

export const clearResults = () => {
	return { type: actionTypes.USERACTIVITY_CLEAR_RESULTS }
}

export const loadUserActivities = (filters: IUserActivityFilter) => {
	return (dispatch: any) => {
		dispatch(loadInProgress())
		const api = new UserActivityApiService()
		api
			.getUsersActions(filters)
			.then(result => {
				dispatch(loadActivitiesSuccess(result))
			})
			.catch(error => {
				dispatch(loadError())
			})
	}
}

export const loadFilterDictionaries = (filter: IUserActivityFilter) => {
	return (dispatch: any) => {
		dispatch(loadInProgress())
		const api = new UserActivityApiService()
		api
			.getFilterDictionaries()
			.then(result => {
				dispatch(loadFilterDictionariesSuccess(result))
			})
			.catch(error => {
				dispatch(loadError())
			})
	}
}

export const changeFilterToProcesses = () => {
	return { type: actionTypes.USERACTIVITY_CHANGE_FILTER_TO_PROCESSES }
}

export const changeMoreFiltersOnProcesses = (currentMoreFilters: boolean) => {
	return { type: actionTypes.USERACTIVITY_CHANGE_PROCESSES_MORE_FILTERS, currentMoreFilters }
}

export const changeFilterState = (newFilters: IUserActivityFilterState) => {
	return { type: actionTypes.USERACTIVITY_CHANGE_FILTERS, newFilters }
}
