export const CONFIGURATION_LOAD_ERROR = 'CONFIGURATION_LOAD_ERROR';
export const CONFIGURATION_LOAD_IN_PROGRESS = 'CONFIGURATION_LOAD_IN_PROGRESS';

export const CONFIGURATION_LOAD_CUSTOMERS_SUCCESS = 'CONFIGURATION_LOAD_CUSTOMERS_SUCCESS';
export const CONFIGURATION_LOAD_CUSTOMER_SUCCESS = 'CONFIGURATION_LOAD_CUSTOMER_SUCCESS';
export const CONFIGURATION_LOAD_CAPABILITIES_SUCCESS = 'CONFIGURATION_LOAD_CAPABILITIES_SUCCESS';
export const CONFIGURATION_FILTER_CAPABILITIES_SUCCESS = 'CONFIGURATION_FILTER_CAPABILITIES_SUCCESS';
export const CONFIGURATION_LOAD_PROCESS_DEFINITION_SUCCESS = 'CONFIGURATION_LOAD_PROCESS_DEFINITION_SUCCESS';
export const CONFIGURATION_LOAD_PROCESS_CHANGEHISTORY_CLEAR = 'CONFIGURATION_LOAD_PROCESS_CHANGEHISTORY_CLEAR';
export const CONFIGURATION_LOAD_PROCESS_CHANGEHISTORY_SUCCESS = 'CONFIGURATION_LOAD_PROCESS_CHANGEHISTORY_SUCCESS';
export const CONFIGURATION_CHANGE_CUSTOMER = 'CONFIGURATION_CHANGE_CUSTOMER';

export const CONFIGURATION_SAVE_ERROR = 'CONFIGURATION_SAVE_ERROR';
export const CONFIGURATION_SAVE_IN_PROGRESS = 'CONFIGURATION_SAVE_IN_PROGRESS';
export const CONFIGURATION_SAVE_CUSTOMER_SUCCESS = 'CONFIGURATION_SAVE_CUSTOMER_SUCCESS';
