import {IMessageDetailsContainerState, IMessageDetailsContainerAction} from "./model/MessageDetailsModel";
import * as actionTypes from './actions/MessageDetailsActionTypes';

const initialState: IMessageDetailsContainerState = {
    loading: false,
    errorMessage: '',
    originalPayload: '',
    data: {
        id: '',
        status: '',
        messageType: '',
        correlationId: '',
        uniqueId: '',
        systemName: '',
        createdDate: '',
        customer: '',
        monitorMessage: '',
    }
}

export function messageDetailsReducer(state: IMessageDetailsContainerState = initialState, action: IMessageDetailsContainerAction) {
    switch (action.type) {
        case actionTypes.MESSAGEDETAILS_LOAD_ITEM_IN_PROGRESS:
            return {...state, loading:true }
        case actionTypes.MESSAGEDETAILS_LOAD_ITEM_SUCCESS:
            return {...state, data: action.view.data, originalPayload:action.view.originalPayload, loading: false}
        case actionTypes.MESSAGEDETAILS_LOAD_ITEM_ERROR:
            return {...state, data: initialState.data, loading: false, errorMessage: action.view.errorMessage}
    }
    return state;
}