import * as React from "react"
import * as moment from "moment"
import {
	FilterSection,
	InputFormGroup,
	DropDownFormGroup,
	IFilterSectionProps,
	DatePickerSection,
	IDropDownItem,
	CheckboxFormGroup
} from "../common"

interface IUserActivityFilterSectionProps extends IFilterSectionProps {
	userName?: string
	userEmail?: string
	correlationId?: string
	uniqueId?: string
	selectedProcess?: string
	selectedCapability?: string
	selectedCustomer?: string
	selectedActivityType?: string
	processes: IDropDownItem[]
	capabilities: IDropDownItem[]
	customers: IDropDownItem[]
	activityTypes: IDropDownItem[]
	fromNumberOfDays?: number
	toNumberOfDays?: number
	fromCurrentDate?: moment.Moment
	toCurrentDate?: moment.Moment
	fromMaxDate?: moment.Moment
	toMaxDate?: moment.Moment
	moreFilters?: boolean
	onChange?: (e: any) => void
	onClear?: () => void
	onMoreFilters?: () => void
}

export class UserActivityFilterSection extends React.Component<IUserActivityFilterSectionProps> {
	public quickFilterTitle = "User name"
	public quickFilterName = "userName"
	constructor(props: IUserActivityFilterSectionProps) {
		super(props)
		this.onClear = this.onClear.bind(this)
		this.onChange = this.onChange.bind(this)
	}

	public render() {
		const formGroupClassName = "col-25"

		return (
			<FilterSection
				onApply={this.props.onApply}
				onClear={this.onClear}
				quickFilterTitle={this.quickFilterTitle}
				quickFilterValue={this.props.userName}
				onChange={this.onChange}
				moreFilters={this.props.moreFilters}
				onMoreFilters={this.props.onMoreFilters}
				showDownloadAll={false}
			>
				<div className="grid-wrapper">
					<InputFormGroup
						labelText="User name"
						name="userName"
						value={this.props.userName}
						placeholder="User name"
						formGroupClassName={formGroupClassName}
						onChange={this.onChange}
					/>
					<InputFormGroup
						labelText="User email"
						name="userEmail"
						value={this.props.userEmail}
						placeholder="User Email"
						formGroupClassName={formGroupClassName}
						onChange={this.onChange}
					/>
					<DatePickerSection
						id="date-from"
						name="dateFrom"
						placeholderText="Date From"
						labelText={"Date From"}
						formGroupClassName={formGroupClassName}
						numberOfDays={this.props.fromNumberOfDays}
						onDateSelect={this.onChange}
						selectedDate={this.props.fromCurrentDate}
						maxDate={this.props.fromMaxDate}
						showTimeSelect={true}
						timeInterval={15}
						dateFormat="DD-MM-YYYY HH:mm:ss"
					/>
					<DatePickerSection
						id="date-to"
						name="dateTo"
						placeholderText="Date To"
						labelText={"Date To"}
						formGroupClassName={formGroupClassName}
						numberOfDays={this.props.toNumberOfDays}
						onDateSelect={this.onChange}
						selectedDate={this.props.toCurrentDate}
						maxDate={this.props.toMaxDate}
						showTimeSelect={true}
						timeInterval={15}
						dateFormat="DD-MM-YYYY HH:mm:ss"
					/>
				</div>
				<div className="grid-wrapper">
					<DropDownFormGroup
						name="activityType"
						labelText="Activity Type"
						formGroupClassName={formGroupClassName}
						elements={this.props.activityTypes}
						value={this.props.selectedActivityType}
						onChange={this.onChange}
					/>
					<DropDownFormGroup
						name="customer"
						labelText="Customer"
						formGroupClassName={formGroupClassName}
						elements={this.props.customers}
						onChange={this.onChange}
						value={this.props.selectedCustomer}
					/>
					<DropDownFormGroup
						name={"capability"}
						labelText="Capability"
						formGroupClassName={formGroupClassName}
						elements={this.props.capabilities}
						onChange={this.onChange}
						value={this.props.selectedCapability}
					/>
					<DropDownFormGroup
						name={"process"}
						labelText="Process"
						formGroupClassName={formGroupClassName}
						elements={this.props.processes}
						onChange={this.onChange}
						value={this.props.selectedProcess}
					/>
				</div>
				<div className="grid-wrapper">
					<CheckboxFormGroup
						name={"autoRefresh"}
						labelText="Auto refresh"
						placeholder="Auto refresh"
						formGroupClassName={formGroupClassName}
						onChange={this.onChange}
						value={"true"}
					/>
				</div>
			</FilterSection>
		)
	}

	private onClear() {
		if (this.props.onClear) {
			this.props.onClear()
		}
	}

	private onChange(e: any) {
		if (this.props.onChange) {
			if (e.target.name === "quickFilter") {
				e.target.name = this.quickFilterName
			}

			this.props.onChange(e)
		}
	}
}
