import * as React from "react";
import {IListBoxItem} from "./IListBoxItem";
import "./listbox.css";


interface IListBoxProps {
    options: IListBoxItem[];
    selected: IListBoxItem[];
    onSelect: (selectedItems: IListBoxItem[]) => void;
    title: string;
    sort?: boolean;
    maxHeight: number;
    readonly?: boolean;
}

interface IListBoxState {
    selected: IListBoxItem[];
}

export const ListBoxItemContext = React.createContext({item: {} as IListBoxItem});


export class ListBox extends React.Component<IListBoxProps, IListBoxState> {
    constructor(props: IListBoxProps) {
        super(props);
        this.onItemClick = this.onItemClick.bind(this);
        this.state = {
            selected: this.props.selected
        };
    }

    public componentDidUpdate(prevProps: Readonly<IListBoxProps>, prevState: Readonly<IListBoxState>, snapshot?: any) {
        if (prevProps.options !== this.props.options) {
            this.setState({selected: []});
        }
    }

    public render() {
        const options = this.props.sort
            ? this.props.options.sort((a, b) => (a.label > b.label) ? 1 : -1)
            : this.props.options;
        const optionItems = options.map(option => this.renderOption(option));
        return (
            <div className={"listbox__container"}>
                <div className={"listbox__title"}>{this.props.title}</div>
                <div className={"listbox__items"} style={{height: this.props.maxHeight}}>
                    <ul>
                        {optionItems}
                    </ul>
                </div>
            </div>
        );
    }

    private renderOption(option: IListBoxItem): JSX.Element {
        const isActive = !!this.state.selected.find(o => o.key === option.key);
        let itemContent: string | React.ComponentElement<any, any> = option.label;
        if (!!this.props.children) {
            itemContent = this.props.children as React.ComponentElement<any, any>;
        }

        return (

            <li className={isActive ? "listbox__item--active" : ""} onClick={this.onItemClick} key={option.key}
                data-key={option.key}>
                <ListBoxItemContext.Provider value={{item: option}}>
                    {itemContent}
                </ListBoxItemContext.Provider>
            </li>

        )
    }

    private onItemClick(event: React.MouseEvent<HTMLLIElement>) {
        if(this.props.readonly) {
            return;
        }
        const key = event.currentTarget.getAttribute('data-key');
        const selected = this.state.selected;
        const item = this.props.options.find(o => o.key === key);
        if (!item) {
            return;
        }
        const itemInSelected = selected.find(o => o.key === key);
        if (itemInSelected === undefined) {
            selected.push(item)
        } else {
            selected.splice(selected.indexOf(itemInSelected), 1);
        }
        this.setState({selected}, () => this.props.onSelect(this.state.selected));
    }


}