import * as actionTypes from './ActionTypes';
import {IDropDownItem} from "src/common";
import {ICustomerConfigReducerAction} from "./Reducer";
import {notify} from "react-notify-toast";
import {Dispatch} from "redux";
import {ProcessesApi, CapabilitiesApi, CustomersApi } from "src/api";
import {ICapability, ICustomer, IProcessChangeHistory, IProcessDefinition} from 'src/api/models';

const customersApi = new CustomersApi();
const capabilitiesApi = new CapabilitiesApi();
const processesApi = new ProcessesApi();

export const loadCustomer = (customerName: string) => {
    return (dispatch: any) => {
        dispatch(loadInProgress());
        customersApi.getCustomer(customerName)
            .then(customer => {
                dispatch(loadCustomerSuccess(customer));
            })
            .catch(error => {
                dispatch(loadError(error));
            });
    };
}

export const loadCustomers = () => {
    return (dispatch: any) => {
        dispatch(loadInProgress());
        customersApi.getCustomers()
            .then(customers => {
                dispatch(loadCustomersSuccess(customers));
            })
            .catch(error => {
                dispatch(loadError(error));
            });
    };
}

export const loadCapabilities = () => {
    return (dispatch: any) => {
        dispatch(loadInProgress());
        capabilitiesApi.getCapabilities()
            .then(capabilities => {
                dispatch(loadCapabilitiesSuccess(capabilities));
            })
            .catch(error => {
                dispatch(loadError(error));
            });
    };
}

export const loadProcessDefinition = (processName: string, customerName: string) => {
    return (dispatch: Dispatch) => {
        dispatch(loadInProgress());
        processesApi.getProcessDefinition(processName)
            .then(processDefinition => {
                    dispatch(loadProcessDefinitionSuccess(processDefinition));
                    loadProcessChangeHistory(processName, customerName)(dispatch);
                }
            )
            .catch(error => dispatch(loadError(error)));
    };
}

export const loadProcessChangeHistory = (name: string, customer: string) => {
    return (dispatch: Dispatch) => {
        dispatch(loadInProgress());
        dispatch(clearProcessChangeHistory())
        processesApi.getProcessChangeHistory(name, customer)
            .then(processChangeHistory =>
                dispatch(loadProcessChangeHistorySuccess(processChangeHistory))
            )
            .catch(error => {
                dispatch(loadError(error));
            });
    };
}

export const saveCustomer = (customer: ICustomer) => {
    return (dispatch: any) => {
        dispatch(saveInProgress());
        return customersApi.putCustomer(customer)
            .then(item => {
                dispatch(saveCustomerSuccess());
                dispatch(loadCustomer(customer.id));
            })
            .catch(error => {
                console.log(error.message);
                dispatch(saveError(error));
                throw error.message;
            });
    };
}


function loadError(error: Error): ICustomerConfigReducerAction {
    notify.show(error.message, "error");
    return {
        type: actionTypes.CONFIGURATION_LOAD_ERROR,
        newState: {isLoadingData: false}
    };
}

function saveError(error: Error): ICustomerConfigReducerAction {
    notify.show(error.message, "error");
    return {
        type: actionTypes.CONFIGURATION_SAVE_ERROR,
        newState: {isLoadingData: false}
    };
}

function loadInProgress(): ICustomerConfigReducerAction {
    return {
        type: actionTypes.CONFIGURATION_LOAD_IN_PROGRESS,
        newState: {isLoadingData: true}
    };
}

function loadCustomersSuccess(customers: IDropDownItem[]): ICustomerConfigReducerAction {
    return {
        type: actionTypes.CONFIGURATION_LOAD_CUSTOMERS_SUCCESS,
        newState: {customers, isLoadingData: false}
    };
}

function loadCustomerSuccess(customer: ICustomer): ICustomerConfigReducerAction {
    return {
        type: actionTypes.CONFIGURATION_LOAD_CUSTOMER_SUCCESS,
        newState: {customer, isLoadingData: false}
    };
}

function loadCapabilitiesSuccess(capabilities: ICapability[]): ICustomerConfigReducerAction {
    return {
        type: actionTypes.CONFIGURATION_LOAD_CAPABILITIES_SUCCESS,
        newState: {capabilities, isLoadingData: false}
    };
}

function loadProcessDefinitionSuccess(processDefinition: IProcessDefinition): ICustomerConfigReducerAction {
    return {
        type: actionTypes.CONFIGURATION_LOAD_PROCESS_DEFINITION_SUCCESS,
        newState: {processDefinition, isLoadingData: false}
    };
}

function loadProcessChangeHistorySuccess(changeHistory: IProcessChangeHistory[]): ICustomerConfigReducerAction {
    return {
        type: actionTypes.CONFIGURATION_LOAD_PROCESS_CHANGEHISTORY_SUCCESS,
        newState: {changeHistory, isLoadingData: false}
    };
}

function saveInProgress(): ICustomerConfigReducerAction {
    return {
        type: actionTypes.CONFIGURATION_SAVE_IN_PROGRESS,
        newState: {isLoadingData: true}
    };
}

function saveCustomerSuccess(): ICustomerConfigReducerAction {
    notify.show("Customer definition saved successfully!", "success");
    return {
        type: actionTypes.CONFIGURATION_SAVE_CUSTOMER_SUCCESS,
        newState: {isLoadingData: false}
    };
}

function clearProcessChangeHistory(): ICustomerConfigReducerAction {
    return {
        type: actionTypes.CONFIGURATION_LOAD_PROCESS_CHANGEHISTORY_CLEAR,
        newState: {changeHistory: []}
    };
}
