import * as React from "react"
import { withRouter } from "react-router-dom"
import { PageHeader, IDropDownItem, LoadingAnimation } from "../common"
import { UserActivityFilterSection } from "./UserActivityFilterSection"
import { IActivity, IUserActivityFilter } from "./UserActivityApi"
import { UserActivityResult } from "./UserActivityResult"
import { compose, bindActionCreators } from "redux"
import { connect } from "react-redux"
import * as userActivityActions from "./userActivityActions"
import { IRootReducer } from "../store/rootReducer"
import * as moment from "moment"

export interface IUserActivityFilterState extends IUserActivityFilter {
	processes: IDropDownItem[]
	capabilities: IDropDownItem[]
	customers: IDropDownItem[]
	activityTypes: IDropDownItem[]
	dateFrom?: moment.Moment
	dateTo?: moment.Moment
	userName?: string
}

export interface IUserActivityContainerState {
	intervalId: any
}

export interface IUserActivityContainer {
	isLoading: boolean
	errorLoading: boolean
	loadingErrorMessage: string
	activities: IActivity[]
	filter: IUserActivityFilterState
	actions: any
	history: any
	location: any
	nextPageToken: string
	processesMoreFilters?: boolean
	stepsMoreFilters?: boolean
}

export interface IUserActivityHistoryParams {
	capability?: string
	process?: string
	processStatus?: string
	dateFrom?: string
	dateTo?: string
}

class UserActivityContainer extends React.Component<IUserActivityContainer, IUserActivityContainerState> {
	constructor(props: any) {
		super(props)

		this.state = {
			intervalId: null
		}

		this.onFilterApply = this.onFilterApply.bind(this)
		this.onFilterChange = this.onFilterChange.bind(this)
		this.onFilterClear = this.onFilterClear.bind(this)
		this.loadUserActivities = this.loadUserActivities.bind(this)
		this.onItemSelect = this.onItemSelect.bind(this)
		this.onProcessesMoreFiltersChanged = this.onProcessesMoreFiltersChanged.bind(this)
	}

	public componentDidMount() {
		this.loadFilterDictionaries()
	}

	public componentWillUnmount() {
		if (this.state.intervalId) {
			clearInterval(this.state.intervalId)
		}
	}

	public render() {
		return (
			<>
				<PageHeader title="User Activity" />
				{this.props.isLoading && <LoadingAnimation />}

				<UserActivityFilterSection
					uniqueId={this.props.filter.uniqueId}
					userName={this.props.filter.userName}
					userEmail={this.props.filter.userEmail}
					capabilities={this.props.filter.capabilities}
					processes={this.props.filter.processes}
					customers={this.props.filter.customers}
					activityTypes={this.props.filter.activityTypes}
					onApply={this.onFilterApply}
					onChange={this.onFilterChange}
					onClear={this.onFilterClear}
					selectedProcess={this.props.filter.process}
					selectedCapability={this.props.filter.capability}
					selectedCustomer={this.props.filter.customer}
					fromCurrentDate={this.props.filter.dateFrom}
					toCurrentDate={this.props.filter.dateTo}
					fromMaxDate={moment(undefined)}
					toMaxDate={moment(undefined)}
					moreFilters={this.props.processesMoreFilters}
					onMoreFilters={this.onProcessesMoreFiltersChanged}
					showDownloadAll={false}
				/>
				<UserActivityResult activities={this.props.activities} />
			</>
		)
	}

	private updateFilters(newState: Partial<IUserActivityFilterState>, callback?: () => void) {
		this.props.actions.changeFilterState({ ...this.props.filter, ...newState })

		if (callback) {
			callback()
		}
	}

	private onProcessesMoreFiltersChanged() {
		if (this.props.processesMoreFilters) {
			this.clearFilters()
		}
		this.props.actions.changeMoreFiltersOnProcesses(this.props.processesMoreFilters)
	}

	private clearFilters() {
		this.updateFilters({
			userEmail: "",
			userName: "",
			correlationId: "",
			uniqueId: "",
			process: "",
			capability: "",
			customer: "",
			activityType: "",
			dateFrom: moment()
				.startOf("day")
				.add(-1, "week"),
			dateTo: moment()
				.startOf("hour")
				.add(1, "hour")
		})
	}

	private onItemSelect(correlationId: string) {
		this.props.history.push("/operational/process/" + correlationId)
	}

	private onFilterApply() {
		this.loadUserActivities()
	}

	private loadUserActivities() {
		const filter: IUserActivityFilter = Object.assign({}, this.props.filter)
		this.props.actions.loadUserActivities(filter)
	}

	private onFilterChange(e: any) {
		const propertyName = e.target.name

		const changedFilter = {}

		if (propertyName === "dateFrom" || propertyName === "dateTo") {
			if (typeof e.target.value === "string") {
				changedFilter[propertyName] = moment(e.target.value)
			}
		} else if (propertyName === "autoRefresh") {
			if (e.target.checked) {
				const id = setInterval(this.onFilterApply, 60000)
				this.setState({
					intervalId: id
				})
			} else {
				clearInterval(this.state.intervalId)
				this.setState({ intervalId: null })
			}
		} else {
			changedFilter[propertyName] = e.target.value
		}

		this.updateFilters(changedFilter)
		this.clearHistoryState()
	}

	private onFilterClear() {
		this.clearFilters()
		this.clearHistoryState()
	}

	private clearHistoryState() {
		this.props.history.replace("/user-activity", null)
	}

	private loadFilterDictionaries() {
		const filter: IUserActivityFilter = Object.assign({}, this.props.filter)
		this.props.actions.loadFilterDictionaries(filter)
	}
}

const mapStateToProps = (state: IRootReducer) => {
	return {
		isLoading: state.userActivityView.isLoading,
		errorLoading: state.userActivityView.errorLoading,
		loadingErrorMessage: state.userActivityView.loadingErrorMessage,
		activities: state.userActivityView.activities,
		filter: state.userActivityView.filter || {},
		nextPageToken: state.userActivityView.nextPageToken,
		processesMoreFilters: state.userActivityView.processesMoreFilters
	}
}

const mapDispatchToProps = (dispatch: any) => {
	return {
		actions: bindActionCreators(userActivityActions, dispatch)
	}
}

export default compose(
	withRouter,
	connect(
		mapStateToProps,
		mapDispatchToProps
	)
)(UserActivityContainer)
