import getHistory from '../common/history';
import { BRAIN_ERROR, BRAIN_ERROR_JSON,BRAIN_ERROR_URL } from './ErrorConstants';
import {IAdvancedError} from "./IAdvancedError";

export class AdvancedError {
    public static FromError(error: IAdvancedError, ex?: any) {
        return new AdvancedError(error.message, error.name, "", ex);
    }

    public friendlyMessage: string;
    public details?: string;
    public errorCode?: string;
    public name?: string;

    constructor( message:string, name?:string, errorCode?:string, details?: string){
      this.details = details;
      this.errorCode = errorCode;
      this.friendlyMessage = message;
      this.name = name;
    }


    public throwAuthError = ( ) => {
      sessionStorage.setItem(BRAIN_ERROR.KEY, BRAIN_ERROR.OCCURED);
      sessionStorage.setItem(BRAIN_ERROR_JSON.KEY, JSON.stringify(this));
    }

    public throw = () => { 
      getHistory().push(BRAIN_ERROR_URL, this);
    }

  }
