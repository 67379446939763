import { StandardColumn } from "./StandardColumn";
import { DateColumn } from "./DateColumn";
import { StringArrayColumn } from "./StringArrayColumn";
import { LinkColumn } from "./LinkColumn";
import { ExpandColumn } from "./ExpandColumn";
import { ColumnWithCustomCell } from "../data-table/ColumnWithCustomCell";
import { BooleanColumn } from "../data-table/BooleanColumn";

export class ColumnFactory {
  public static standardColumn = StandardColumn;

  public static stringArrayColumn = StringArrayColumn;

  public static linkColumn = LinkColumn;

  public static booleanColumn = BooleanColumn;

  public static dateColumn = DateColumn;

  public static columnWithCustomCell = ColumnWithCustomCell;

  public static expandColumn = ExpandColumn;
}
