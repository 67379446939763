import * as React from 'react';
import { Column } from 'react-table';
import * as moment from 'moment';
import { ColumnTheme, setStyles } from "../data-table/utils/columnStyles";
import { header } from './Header';

export const DateColumn = (
    accessor: string,
    name: string,
    config: {
        format: string,
        width?: number,
        resizable?: boolean,
        theme?: ColumnTheme,
    } = { format: 'DD-MM-YYYY' },
): Column => {
    return {
        headerClassName: 'data-table header',
        show: true,
        accessor,
        width: config.width,
        resizable: config.resizable,
        style: {
            cursor: 'pointer',
            ...setStyles(config.theme)
        },
        Header: (rowInfo: any) => header(rowInfo, name),
        Cell: (rowInfo) => {
            if(!rowInfo.value) {
                return(
                    <div />
                )
            }
            return(
                <div>
                    {moment(rowInfo.value).format(config.format)}
                </div>
            )
        }
    };
};