import {ApiServiceBase} from "../../common/ApiServiceBase";
import {IDropDownItem} from "../../common";
import { ICustomer } from "./models";

const CUSTOMERS_URL = '/customers';

export default class CustomersApi extends ApiServiceBase {
    public getCustomer(customerName: string): Promise<ICustomer> {
        return Promise.all([
            this.get(CUSTOMERS_URL + '/' + customerName)
        ])
            .then(items => {
                return new Promise<ICustomer>((resolve) => {
                    resolve(items[0]);
                });
            });
    }

    public getCustomers(withDefault: boolean = true): Promise<IDropDownItem[]> {
        return this.getDropDownItems(CUSTOMERS_URL,
            withDefault?{key:"", text:"Select customer", disabled: true} :undefined
        );
    }

    public putCustomer(customer: ICustomer): Promise<string> {
        return Promise.all([
            this.putData(CUSTOMERS_URL, null, {
                Name: customer.name,
                BECode: customer.beCode,
                ProcessIds: customer.processIds,
                Steps: customer.steps,
                id: customer.id,
                _etag: customer._etag,
            })
        ])
            .then(items => new Promise<string>((resolve) => {
                return resolve(items[0]);
            }));
    }
}