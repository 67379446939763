import { createStore, applyMiddleware } from 'redux';
import immutableStateInvariantMiddleware from 'redux-immutable-state-invariant';
import promiseMiddleware from 'redux-promise-middleware';
import thunk from 'redux-thunk';
import { rootReducer } from './rootReducer';

export function configureStore() {
    return createStore (
        rootReducer, 
        applyMiddleware(thunk, promiseMiddleware(), immutableStateInvariantMiddleware())
    );
}