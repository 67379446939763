import {ICustomerProcessDefinition} from "../models/ICustomerProcessDefinition";
import {ICapability, ICustomer, ICustomerGroup, IFlowItem, IProcessDefinition, IStepDefinition} from "src/api/models";

export function filterCapabilities(capabilities: ICapability[], processIds: IFlowItem[]): ICapability[] {
    const result: ICapability[] = [];
    capabilities.forEach(element => {
        const processes = element.processes.filter(p => processIds.map(c => c.id).indexOf(p) !== -1)
        if (processes.length > 0) {
            result.push({...element, processes})
        }
    })
    return result;
}


// This function creates 'virtual' process definition, which merges both Customer(Group) with ProcessDefinition
// Resulting instance is stored in local state store and contains information regarding process steps and their
// enablement status
export function mapProcessDefinition
(processDefinition: IProcessDefinition, owner: ICustomer | ICustomerGroup): ICustomerProcessDefinition {

    const customerProcessDefinition: ICustomerProcessDefinition = {
        capabilityId: processDefinition.capabilityId || "",
        enabled: processDefinition.enabled || false,
        name: processDefinition.name || "",
        steps: []
    };

    if (owner.processIds === undefined) {
        return customerProcessDefinition;
    }
    const process = owner.processIds?.filter(p => p.id === customerProcessDefinition.name)[0]
    if (!process) {
        return customerProcessDefinition;
    }
    customerProcessDefinition.enabled = process.enabled;
    const resultSteps: IStepDefinition[] = [];

    processDefinition.steps.forEach(step => {
        const steps = owner.steps?.filter(s => s.id === step.messageType)
        if (!!steps && steps.length > 0) {
            step.enabled = steps[0].enabled
            resultSteps.push(step)
        }
    })
    return {...customerProcessDefinition, steps: resultSteps}

}