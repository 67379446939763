import * as React from 'react';
import {connect} from 'react-redux';
import {PageHeader,LoadingAnimation} from '../../../common';
import {IRootReducer} from '../../../store/rootReducer';
import {
    IMessageDetailsContainerState,
    IMessageDetailsContainerProps,
    IOriginalPayloadQuery,
    MessageDetailsItemLabel
} from "../model/MessageDetailsModel";
import {bindActionCreators} from "redux";
import * as detailsActions from '../actions/MessageDetailsActions';

class MessageDetailsContainer extends React.Component<IMessageDetailsContainerProps, IMessageDetailsContainerState> {
    constructor(props: IMessageDetailsContainerProps) {
        super(props);
        this.downloadFile = this.downloadFile.bind(this);
    }

    public componentDidMount() {
        this.getDetails(this.props.match.params.id);
    }

    public render() {
        const isLoading = this.props.loading;

        return (
            <div>
                {isLoading && <LoadingAnimation/>}
                <div>
                    <PageHeader title="Rejected Message Details"/>
                    <section className="page-container">
                        <div className="grid-wrapper">
                            <div className="col-50">
                                <form className={"filter-search-criteria"}>
                                    <fieldset>
                                        {this.renderDetailedInformation()}
                                    </fieldset>
                                </form>
                            </div>
                            <div className="col-50">
                                <form className={"filter-search-criteria"}>
                                    <fieldset>
                                        <div className="filter-header">
                                            <h3>Original Payload</h3>
                                        </div>
                                        <div>
                                            {!!this.props.originalPayload ? this.renderDownloadLink() : this.renderFileMissing()}
                                        </div>
                                    </fieldset>
                                </form>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        )
    }

    private renderFileMissing(): JSX.Element {
        return(<span className="regular-body-text">Original payload not delivered to Boss</span>)
    }

    private renderDownloadLink(): JSX.Element {
        return (<div className={"grid-wrapper filter-body"}>
                                                <span className="regular-body-text">
                                                    <a href={"#"} onClick={this.downloadFile}
                                                       data-url={this.props.originalPayload}
                                                       data-system={this.props.data.systemName}>Download</a>
                                                </span>
        </div>)
    }

    private renderDetailedInformationItem(key: string): JSX.Element {
        return (
            <tr key={key}>
                <td>{MessageDetailsItemLabel[key]}</td>
                <td><span className="regular-link-text result-item">{this.props.data[key]}</span></td>
            </tr>
        );
    }

    private renderDetailedInformation(): JSX.Element {
        return (
            <fieldset>
                <div className="filter-header">
                    <h3>Detailed Information <i className="fa fa-refresh fa-lg"
                                                style={{cursor: "pointer", marginLeft: "7px"}}
                    />
                    </h3>
                </div>
                <div>
                    <div className={"grid-wrapper filter-body"}>
                        <table>
                            <tbody>
                            {
                                Object.keys(this.props.data).map((key, index) => {
                                    return this.renderDetailedInformationItem(key);
                                })
                            }
                            </tbody>
                        </table>
                    </div>
                </div>
            </fieldset>
        )
    }

    private getDetails(id: string) {
        this.props.actions.loadItem(id);
    }

    private downloadFile(e: any) {
        const query: IOriginalPayloadQuery = {
            Path: e.currentTarget.getAttribute('data-url'),
            System: e.currentTarget.getAttribute('data-system').toLowerCase()
        }

        this.props.actions.downloadFile(query);
    }
}

const mapStateToProps = (state: IRootReducer, ownProps: any) => {
    return {
        loading: false,
        data: state.messageDetailsView.data,
        originalPayload: state.messageDetailsView.originalPayload
    };
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        actions: bindActionCreators(detailsActions, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(MessageDetailsContainer);