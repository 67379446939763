import {MonitorFilterSection} from "../../common/monitor/components/MonitorFilterSection";
import {DatePickerSection, DropDownFormGroup, InputFormGroup} from "../../common";
import * as React from "react";

export class RejectedMessagesFilterSection extends MonitorFilterSection{
    protected renderFilters(): JSX.Element | undefined {
        const formGroupClassName = "col-25";
        return (
            <div>
                <div className="grid-wrapper">
                    <InputFormGroup
                        labelText="Correlation ID"
                        name={"CorrelationId"}
                        value={this.props.correlationId}
                        placeholder="Correlation ID"
                        formGroupClassName={formGroupClassName}
                        onChange={this.onChange}
                    />

                    <DropDownFormGroup
                        name={"System"}
                        labelText="System"
                        formGroupClassName={formGroupClassName}
                        elements={this.props.systemsList}
                        onChange={this.onChange}
                        value={this.props.selectedSystem}
                    />

                    <DropDownFormGroup
                        name={"RejectionType"}
                        labelText="Rejection Reason"
                        formGroupClassName={formGroupClassName}
                        elements={this.props.rejectionReasons}
                        onChange={this.onChange}
                        value={this.props.selectedRejectionReason}
                    />
                </div>
                <div className="grid-wrapper">
                    <InputFormGroup
                        name={"UniqueId"}
                        labelText="Unique ID"
                        placeholder="Unique ID"
                        formGroupClassName={formGroupClassName}
                        onChange={this.onChange}
                        value={this.props.uniqueId}
                    />

                    <DatePickerSection
                        id="create-process-from"
                        name="DateFrom"
                        placeholderText="Date From"
                        labelText={"Date From"}
                        formGroupClassName={formGroupClassName}
                        numberOfDays={this.props.fromNumberOfDays}
                        onDateSelect={this.onChange}
                        selectedDate={this.props.fromCurrentDate}
                        maxDate={this.props.fromMaxDate}
                        showTimeSelect={true}
                        timeInterval={15}
                        dateFormat="DD-MM-YYYY HH:mm"
                    />

                    <DatePickerSection
                        id="create-process-to"
                        name="DateTo"
                        placeholderText="Date To"
                        labelText={"Date To"}
                        formGroupClassName={formGroupClassName}
                        numberOfDays={this.props.toNumberOfDays}
                        onDateSelect={this.onChange}
                        selectedDate={this.props.toCurrentDate}
                        maxDate={this.props.toMaxDate}
                        showTimeSelect={true}
                        timeInterval={15}
                        dateFormat="DD-MM-YYYY HH:mm"
                    />
                </div>
            </div>
        )
    }
}