import * as React from 'react';
import { connect } from 'react-redux';
import { compose, bindActionCreators } from 'redux';
import * as dashboardActions from './dashboardActions';
import { ChartsSection } from './ChartsSection';
import { CapabilityLinksSection } from './CapabilityLinksSection';
import { LoadingAnimation } from '../common/LoadingAnimation';
import { ICapabilityLinksProps, ICapabilityChartsProps, IChartsFilter } from './dashboardModel';
import { IRootReducer } from '../store/rootReducer';
import * as moment from 'moment';
import './styles/dashboard.css';
import { withRouter } from 'react-router-dom';
import { PageHeader } from '../common';
import { IOperationalHistoryParams } from '../operational/OperationalContainer';

export interface IDashboardContainer {
    isLoadingCapabilityLinks: boolean,
    isLoadingCharts: boolean,
    errorLoading: boolean;
    loadingErrorMessage: string;
    capabilityCharts: ICapabilityChartsProps;
    capabilityLinks: ICapabilityLinksProps;
    actions: any;
    filter: IChartsFilter;
    history: any;
}

export interface IDashboardContainerState {
    isLoading: boolean,
    errorLoading: boolean;
    loadingErrorMessage: string;
    capabilityCharts: ICapabilityChartsProps;
    capabilityLinks: ICapabilityLinksProps;
    filter: IChartsFilter;
}

class DashboardContainer extends React.Component<IDashboardContainer, IDashboardContainerState> {
    constructor(props: IDashboardContainer) {
        super(props);
        this.state = {
            isLoading: true,
            errorLoading: false,
            loadingErrorMessage: '',
            capabilityCharts: {isShowCapabilityBar: false, capabilities: [{id:"", name:"", states:[]}], allStates: []},
            capabilityLinks: {pageCount:0, capabilities: []},
            filter: {selectedCapability: "", dateFrom: moment().add(-7, 'days'), dateTo: moment(), isShowCapabilityBar: false}
        }
        
        this.loadCapabilityCharts = this.loadCapabilityCharts.bind(this);
        this.loadCapabilityLinks = this.loadCapabilityLinks.bind(this);
        this.onCheckboxSelected = this.onCheckboxSelected.bind(this);
        this.onDateSelected = this.onDateSelected.bind(this);
        this.onCharElementClick = this.onCharElementClick.bind(this);
    }

    public render() {
        const isLoading = this.props.isLoadingCharts || this.props.isLoadingCapabilityLinks;
        return (
            <div>
                <PageHeader title="Dashboard" />
                { isLoading ?
                    (
                        <LoadingAnimation/>
                    ) :
                    (
                        <section className="page-container">
                            <div className="grid-wrapper">
                                <div className="row">
                                    <ChartsSection  isShowCapabilityBar={this.state.filter.isShowCapabilityBar}
                                                    capabilities={this.props.capabilityCharts.capabilities}
                                                    allStates={this.props.capabilityCharts.allStates}
                                                    onChange={this.onDateSelected}
                                                    selectedCapability={this.state.filter.selectedCapability}
                                                    dateFrom={this.state.filter.dateFrom}
                                                    dateTo={this.state.filter.dateTo}
                                                    onCharElementClick={this.onCharElementClick}
                                    />
                                </div>
                                <div className="grid-wrapper title dashboard-margin">
                                    <h3 className="title-blue-underline"/>
                                </div>
                                <div className="row">
                                    <CapabilityLinksSection onChange={this.onCheckboxSelected}
                                                        pageCount={5}
                                                        selectedCapability={this.state.filter.selectedCapability}
                                                        capabilities={this.props.capabilityLinks.capabilities} />
                                </div>
                            </div>
                        </section>
                    )
                }
            </div>
        );
    }

    public componentDidMount() {
        this.loadCapabilityCharts();
        this.loadCapabilityLinks();
    }

    private loadCapabilityCharts() {
        const filter: IChartsFilter = Object.assign({}, this.state.filter);
        this.props.actions.loadCapabilityCharts(filter);
    }

    private loadCapabilityLinks() {
        this.props.actions.loadCapabilityLinks();
    }

    private onDateSelected(event: any) {
        const selectedSystems = this.state.filter.selectedCapability;
        let dateFrom = this.state.filter.dateFrom;
        let dateTo = this.state.filter.dateTo;
        if(event.target.name === "from-date"){
            dateFrom = moment(event.target.value);
        }
        if(event.target.name === "to-date"){
            dateTo = moment(event.target.value);
        }
        this.updateCharts(selectedSystems, dateFrom, dateTo);
        this.props.actions.loadCapabilityLinks();
    }

    private onCheckboxSelected(event: any) {
        const dateFrom = this.state.filter.dateFrom;
        const dateTo = this.state.filter.dateTo;
        let selectedCapability = event.target.value
        if(this.state.filter.selectedCapability === event.target.value){
            selectedCapability = ""
        }
        this.updateCharts(selectedCapability, dateFrom, dateTo);
        this.props.actions.loadCapabilityLinks();
    }

    private updateCharts(selectedCapability: string, dateFrom: moment.Moment, dateTo: moment.Moment){
        let isShowCapabilityBar = true
        if(!selectedCapability){
            isShowCapabilityBar = false
        }
        this.setState({filter: {isShowCapabilityBar, selectedCapability, dateFrom, dateTo}}, () => {this.props.actions.loadCapabilityCharts(this.state.filter)});
    }

    private onCharElementClick(chartElements: any){
        if(chartElements){
            if(chartElements.length === 1){
                if(this.state.filter.isShowCapabilityBar){
                    const state: IOperationalHistoryParams = {
                        capability: this.state.filter.selectedCapability,
                        process: chartElements[0]._model.label,
                        processStatus: "Failed",
                        createDateFrom: this.state.filter.dateFrom.toISOString(),
                        createDateTo: this.state.filter.dateTo.toISOString()
                    }
                    this.props.history.push('/operational/process/', state);
                }
                else{
                    const dateFrom = this.state.filter.dateFrom;
                    const dateTo = this.state.filter.dateTo;
                    this.updateCharts(chartElements[0]._model.label, dateFrom, dateTo);
                    this.props.actions.loadCapabilityLinks();
                }
            }
        }
    }
}

const mapStateToProps = (state: IRootReducer, ownProps: any) => {
    return {
        isLoadingCharts: state.dashboardView.isLoadingCharts,
        isLoadingCapabilityLinks: state.dashboardView.isLoadingCapabilityLinks,
        errorLoading: state.dashboardView.errorLoading,
        loadingErrorMessage: state.dashboardView.loadingErrorMessage,
        capabilityCharts: state.dashboardView.capabilityCharts || {isShowCapabilityBar: false, capability: {}},
        capabilityLinks: state.dashboardView.capabilityLinks || {pageCount:0, capabilities: []},
    };
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        actions: bindActionCreators(dashboardActions, dispatch)
    };
}

export default compose (
    withRouter,
    connect(mapStateToProps, mapDispatchToProps)
)
(DashboardContainer);