import { ApiServiceBase } from "../common/ApiServiceBase";
import { IDropDownItem } from "../common";
import { IOperationalFilterState } from "./OperationalContainer";
import { IOperationalFilter, IProcess } from "./OperationalApi";

const GET_CAPABILITIES_URL = '/capabilities';
const GET_PROCESSES_STATUSES = '/process-instances/statuses';
const GET_CUSTOMERS = '/customers';
const GET_PROCESSES_URL = '/processes';
const POST_PROCESSES_INSTANCES_URL = '/process-instances';

interface IProcessQuery {
    CorrelationId?: string;
    CarrierBookingRequestId?: string;
    UniqueId?: string;
    Capability?: string;
    ProcessName?: string;
    ProcessState?: string;
    From?: string;
    To?: string;
    Customer?: string;
    NextPageToken?: string;
}

export interface IProcessResult {
    processes: IProcess[];
    nextPageToken: string;
    nextPageExists: boolean;
}

export class OperationalApiService extends ApiServiceBase {

    public getProcessesInstances(filter: IOperationalFilter): Promise<IProcessResult> {
        const processQuery = this.filterToQueryParameter(filter);

        return this.postData(POST_PROCESSES_INSTANCES_URL, null, processQuery)
            .then(response => {
                const result: IProcessResult = {
                    processes: [],
                    nextPageExists: false,
                    nextPageToken: ''
                };

                if(response.nextPageToken && response.nextPageToken.length > 0) {
                    result.nextPageToken = response.nextPageToken;
                    result.nextPageExists = true;
                }

                response.data.forEach((process: any) => {
                    result.processes.push({
                        Id: process.id,
                        CorrelataionId: process.correlationId,
                        SystemName: process.system,
                        ProcessName: process.name,
                        ProcessStatusName: process.processState,
                        CreatedDate: process.createdUtc,
                        CustomerName: process.customer,
                        TransactionId: process.transactionId,
                        Capability: process.capability,
                        UniqueId: process.uniqueId
                    });
                });

                return new Promise<IProcessResult> (resolve => resolve(result));
            });
    }

    public downloadAllFiles(processInstanceIds: string[]): Promise<void> {
        return new Promise((resolve) => {
            this.postRawData('/process-instances/files-references', processInstanceIds)
            .then((response: Response) => {
                if (response.ok){
                    
                    response.blob().then(blob =>
                    {
                        if (typeof window.navigator.msSaveBlob !== 'undefined') {
                            // IE workaround for "HTML7007: One or more blob URLs were revoked by closing the blob for which they were created. These URLs will no longer resolve as the data backing the URL has been freed."
                            window.navigator.msSaveBlob(blob, 'payloads.zip');
                        } else {
                            const URL = window.URL || (window as any).webkitURL;
                            const downloadUrl = URL.createObjectURL(blob);
        
                            // use HTML5 a[download] attribute to specify filename
                            const a = document.createElement("a");
                            // safari doesn't support this yet
                            if (typeof a.download === 'undefined') {
                                window.location.href = downloadUrl;
                            } else {
                                a.href = downloadUrl;
                                a.download = 'payloads.zip';
                                document.body.appendChild(a);
                                a.click();
                                a.remove();
                            }
                            setTimeout(() => { URL.revokeObjectURL(downloadUrl); }, 100); // cleanup
                        }
                        resolve();
                    });
                } else {
                    alert('Error downloading file. Status:' + response.status);
                    resolve();
                }
            });          
        });
    }

    public getFilterDictionaries(): Promise<IOperationalFilterState> {
        return Promise.all([
            this.getCapabilities(),
            this.getProcessStatuses(),
            this.getProcesses(),
            this.getCustomers()
        ])
        .then(items => {
            const filterState: IOperationalFilterState = {
                capabilitiesList: items[0],
                processStatuses: items[1],
                processesList: items[2],
                customers: items[3]
            };

            return new Promise<IOperationalFilterState>((resolve) => {
                resolve(filterState);
            });
        });
    }

    private getCapabilities(): Promise<IDropDownItem[]> {
        return this.getDropDownItems(GET_CAPABILITIES_URL, { key: '', text: 'All capabilities'});
    }

    private getProcessStatuses(): Promise<IDropDownItem[]> {
        return this.getDropDownItems(GET_PROCESSES_STATUSES, { key: '', text: 'All statuses'});
    }

    private getProcesses(): Promise<IDropDownItem[]> {
        return this.getDropDownItems(GET_PROCESSES_URL, { key: '', text: 'All processes'});
    }

    private getCustomers(): Promise<IDropDownItem[]> {
        return this.getDropDownItems(GET_CUSTOMERS, { key: '', text: 'All customers'});
    }

    private filterToQueryParameter(filter: IOperationalFilter): IProcessQuery {
        const processQuery: IProcessQuery = {
            CorrelationId: filter.correlationId,
            CarrierBookingRequestId: filter.carrierBookingRequestId,
            Capability: filter.capability,
            ProcessName: filter.process,
            ProcessState: filter.processStatus,
            Customer: filter.customer,
            NextPageToken: filter.nextPageToken,
            From: filter.createDateFrom ? filter.createDateFrom.toISOString() : '',
            To: filter.createDateTo ? filter.createDateTo.toISOString() : '',
            UniqueId: filter.uniqueId
        };

        return processQuery;
    }
}