export const DATA_LOAD_IN_PROGRESS = "DATA_LOAD_IN_PROGRESS";
export const DATA_LOAD_ERROR = "DATA_LOAD_ERROR";
export const LOAD_GROUP_SUCCESS = "LOAD_GROUP_SUCCESS";
export const CHANGE_GROUP = "CHANGE_GROUP";
export const GROUP_SAVE_IN_PROGRESS = "GROUP_SAVE_IN_PROGRESS";
export const GROUP_SAVE_ERROR = "GROUP_SAVE_ERROR";
export const SAVE_CUSTOMERS_SUCCESS = "SAVE_CUSTOMERS_SUCCESS";
export const SAVE_CUSTOMERS_ERROR = "SAVE_CUSTOMERS_ERROR";
export const SAVE_IN_PROGRESS = "SAVE_IN_PROGRESS";
export const SAVE_PRODUCT_SUCCESS = "SAVE_PRODUCT_SUCCESS";
export const SAVE_PRODUCT_ERROR = "SAVE_PRODUCT_ERROR";
export const LOAD_PRODUCT_SUCCESS = "LOAD_PRODUCT_SUCCESS";
export const LOAD_PRODUCTS_SUCCESS = "LOAD_PRODUCTS_SUCCESS";
export const LOAD_AVAILABLE_CUSTOMERS_SUCCESS =
  "LOAD_AVAILABLE_CUSTOMERS_SUCCESS";
export const LOAD_AVAILABLE_GROUPS_SUCCESS = "LOAD_AVAILABLE_GROUPS_SUCCESS";
