import {Dispatch} from "redux";
import * as actionTypes from './ActionTypes';
import CapabilitiesApi from "src/api/capabilities";
import {IDropDownItem} from "src/common";
import {ProcessesApi, CustomerGroupApi, CustomersApi} from "src/api";
import {IGroupConfigReducerAction} from "./Reducer";
import {notify} from "react-notify-toast";
import {ICapability, ICustomerGroup, ICustomerGroupDifferences, IProcessDefinition} from "src/api/models";

const customersApi = new CustomersApi();
const customerGroupApi = new CustomerGroupApi();
const capabilitiesApi = new CapabilitiesApi();
const processesApi = new ProcessesApi();


export const loadCustomers = () => {
    return (dispatch: any) => {
        dispatch(loadInProgress());
        customersApi.getCustomers(false)
            .then(customers => {
                dispatch(loadCustomersSuccess(customers));
            })
            .catch(error => {
                dispatch(loadError(error));
            });
    };
}

export const loadCustomerGroups = () => {
    return (dispatch: Dispatch) => {
        dispatch(loadInProgress());
        customerGroupApi
            .getCustomerGroupsAsDropDown()
            .then(customerGroups => dispatch(loadCustomerGroupsSuccess(customerGroups)))
            .catch(error => dispatch(loadError(error)));

    }
}

export const loadCustomerGroup = (customerGroupName: string) => {
    return (dispatch: Dispatch) => {
        dispatch(loadInProgress());
        customerGroupApi.getCustomerGroup(customerGroupName)
            .then(customerGroup => dispatch(setCustomerGroup(customerGroup)))
            .catch(error => dispatch(loadError(error)))
    }
}

export const loadCapabilities = () => {
    return (dispatch: Dispatch) => {
        dispatch(loadInProgress());
        capabilitiesApi.getCapabilities()
            .then(capabilities => dispatch(loadCapabilitiesSuccess(capabilities)))
            .catch(error => dispatch(loadError(error)));
    };
}

export const loadProcessDefinition = (name: string) => {
    return (dispatch: Dispatch) => {
        dispatch(loadInProgress());
        processesApi.getProcessDefinition(name)
            .then(processDefinition => dispatch(setProcessDefinition(processDefinition)))
            .catch(error => dispatch(loadError(error)));
    }
}

export const updateProcessDefinition = (processDefinition: IProcessDefinition) => {
    return (dispatch: Dispatch) => dispatch(setProcessDefinition(processDefinition));
}

export const saveCustomerGroup = (customerGroup: ICustomerGroup) => {
    return (dispatch: Dispatch) => {
        //     dispatch(loadInProgress());
        return Promise.resolve()
            .then(() => {
                notify.show("Customer definition saved successfully!", "success")
            })
            .catch((error: string) => {
                notify.show(error, "error")
            });
    }
}

export const loadCustomerGroupDifferences = (customerGroupName: string) => {
    return (dispatch: Dispatch) => {
        dispatch(loadInProgress());
        customerGroupApi.getCustomerGroupDifferences(customerGroupName)
            .then((result) => dispatch(loadCustomerGroupDifferencesSuccess(result, false)))
            .catch((error: Error) => {
                loadError(error);
                dispatch(loadCustomerGroupDifferencesError())
            });
    }
}

export const appendCustomerGroupDifferences = (customerGroupName: string, customers: string[]) => {
    return (dispatch: Dispatch) => {
        dispatch(loadInProgress());
        customerGroupApi.getCustomersDifferences(customerGroupName, customers)
            .then((result) => dispatch(loadCustomerGroupDifferencesSuccess(result, true)))
            .catch((error: Error) => {
                loadError(error);
                dispatch(loadCustomerGroupDifferencesError())
            });
    }
}

export const synchronizeCustomerWithGroup = (customerGroupName: string, customer: string) => {
    return (dispatch: Dispatch) => {
        customerGroupApi.synchronizeCustomerWithGroup(customerGroupName, customer)
            .then((result) => {
                if (result) {
                    dispatch(synchronizeCustomerWithGroupSuccess(customerGroupName, customer));
                    notify.show("Customer synchronized!", "success");
                }
            });
    }
}

export const addCustomerToGroup = (customerGroupName: string, customer: string) => {
    return (dispatch: Dispatch) => {
        customerGroupApi.addCustomerToGroup(customerGroupName, customer)
            .then((result) => {
                if (result) {
                    dispatch(addCustomerToGroupSuccess(customerGroupName, customer));
                    notify.show("Customer added to group!", "success");
                }
            });
    }
}

export const synchronizeCustomerAssignment =
    (customerGroupName: string, assignedCustomers: string[], unassignedCustomers: string[]) => {
        return (dispatch: Dispatch) => {
            customerGroupApi.synchronizeCustomerAssignment(customerGroupName, assignedCustomers, unassignedCustomers)
                .then((result) => {
                    if (result) {
                        notify.show("Customer assignment synchronized!", "success");
                    }
                });
        }
    }


function loadError(error: Error): IGroupConfigReducerAction {
    notify.show(error.message, "error");
    return {type: actionTypes.GROUP_CONFIGURATION_UPDATE_STATE, newState: {isLoading: false}};
}

function loadInProgress(): IGroupConfigReducerAction {
    return {
        type: actionTypes.GROUP_CONFIGURATION_UPDATE_STATE,
        newState: {isLoading: true}
    };
}

function loadCustomerGroupsSuccess(customerGroups: IDropDownItem[]): IGroupConfigReducerAction {
    return {
        type: actionTypes.GROUP_CONFIGURATION_UPDATE_STATE,
        newState: {customerGroups, isLoading: false}
    }
}

function setCustomerGroup(customerGroup: ICustomerGroup): IGroupConfigReducerAction {
    return {
        type: actionTypes.GROUP_CONFIGURATION_UPDATE_STATE,
        newState: {selectedCustomerGroup: customerGroup, isLoading: false, selectedProcessDefinition: null}
    };
}

function loadCapabilitiesSuccess(capabilities: ICapability[]): IGroupConfigReducerAction {
    return {
        type: actionTypes.GROUP_CONFIGURATION_UPDATE_STATE,
        newState: {capabilities, isLoading: false}
    };
}

function setProcessDefinition(processDefinition: IProcessDefinition): IGroupConfigReducerAction {
    return {
        type: actionTypes.GROUP_CONFIGURATION_UPDATE_STATE,
        newState: {selectedProcessDefinition: processDefinition, isLoading: false}
    }
}

function loadCustomersSuccess(customers: IDropDownItem[]): IGroupConfigReducerAction {
    return {
        type: actionTypes.GROUP_CONFIGURATION_UPDATE_STATE,
        newState: {customers, isLoading: false}
    };
}

function loadCustomerGroupDifferencesSuccess(differences: ICustomerGroupDifferences[], append: boolean): IGroupConfigReducerAction {
    return {
        type: append
            ? actionTypes.GROUP_CONFIGURATION_APPEND_PROCESS_DIFFERENCES
            : actionTypes.GROUP_CONFIGURATION_UPDATE_STATE,
        newState: {customerGroupDifferences: differences, isLoading: false}
    }
}

function loadCustomerGroupDifferencesError(): IGroupConfigReducerAction {
    return {
        type: actionTypes.GROUP_CONFIGURATION_UPDATE_STATE,
        newState: {customerGroupDifferences: [], isLoading: false}
    }
}

function synchronizeCustomerWithGroupSuccess(customerGroup: string, customer: string): IGroupConfigReducerAction {
    return {
        type: actionTypes.GROUP_CONFIGURATION_REPLACE_PROCESS_DIFFERENCE,
        newState: {
            customerGroupDifferences: [{
                synced: true,
                beCode: customer,
                customerGroup,
                processesMissingInCustomer: [],
                stepsMissingInCustomer: [],
                processesMissingInCustomerGroup: [],
                stepsMissingInCustomerGroup: []
            }]
        }
    }
}

function addCustomerToGroupSuccess(customerGroup: string, customer: string): IGroupConfigReducerAction {
    return {
        type: actionTypes.GROUP_CONFIGURATION_REPLACE_PROCESS_DIFFERENCE,
        newState: {
            customerGroupDifferences: [{
                synced: true,
                beCode: customer,
                customerGroup,
                processesMissingInCustomer: [],
                stepsMissingInCustomer: [],
                processesMissingInCustomerGroup: [],
                stepsMissingInCustomerGroup: []
            }]
        }
    }
}