import * as React from "react"
import {Link} from "react-router-dom"
import {NavItem} from "./NavItem"
import {IUserPermissions} from "./main"
import {ProfileSection} from "./ProfileSection"
import authentication from "src/common/Authentication"

export interface IProfileContext {
    showProfile: boolean
    userPermissions?: IUserPermissions
}

export interface IMenuContext extends IProfileContext {
    showProfile: boolean
    showBrainMenu: boolean
    showSequencerMenu: boolean
}

export interface INavLinksProps {
    showNavigation: boolean
    userPermissions?: IUserPermissions
}

export class NavLinks extends React.Component<INavLinksProps, IMenuContext> {
    constructor(props: any) {
        super(props)
        this.state = {
            showProfile: false,
            showBrainMenu: false,
            showSequencerMenu: false
        }
    }

    public render() {
        return (
            <nav className="navbar large-nav-menu">
                <Link to="/" className="navbar-brand">
                    <img src="https://design-system.azureedge.net/images/logos/logo-portal.png" alt="Logo -Damco"/>
                </Link>
                {this.props.showNavigation ? (
                    <div className="collapse navbar-collapse" id="navbarCollapse">
                        <ul className="navbar-nav">
                            <li className="nav-item active dropdown">
                                <a className="dropdown-toggle" onClick={this.OnBrainMenuClick}>
                                    Brain
                                    <i className="fas fa-angle-down"/>
                                </a>
                                <ul className={"dropdown-menu " + (this.state.showBrainMenu ? "show" : "hidden")}>
                                    <NavItem onClick={this.OnNavigateClick} to="/dashboard">
                                        Dashboard
                                    </NavItem>
                                    <NavItem onClick={this.OnNavigateClick} to="/operational">
                                        Operational
                                    </NavItem>
                                    <NavItem onClick={this.OnNavigateClick} to="/rejected-messages">
                                        Rejected Messages
                                    </NavItem>
                                    <NavItem onClick={this.OnNavigateClick} to="/configuration/customers/" >
                                        Customer Configuration
                                    </NavItem>
                                    <NavItem onClick={this.OnNavigateClick} to="/configuration/customer-groups/" >
                                        Customer Group Configuration
                                    </NavItem>
                                    <NavItem onClick={this.OnNavigateClick} to="/user-activity">
                                        User activity
                                    </NavItem>
                                    <NavItem onClick={this.OnNavigateClick} to="/groups">
                                        Groups
                                    </NavItem>
                                </ul>
                            </li>
                            <li className="nav-item dropdown">
                                <a className="dropdown-toggle" onClick={this.OnSequencerMenuClick}>
                                    Sequencer
                                    <i className="fas fa-angle-down"/>
                                </a>
                                <ul className={"dropdown-menu " + (this.state.showSequencerMenu ? "show" : "hidden")}>
                                    <li>
                                        <a href={window.location.protocol + "//" + window.location.host + "/sequencer/sequencer/transactions"}>
                                            Transactions
                                        </a>
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </div>
                ) : (
                    ""
                )}
                <div className="icon-bar">
                    <div className="dropdown-wrapper">
                        <div className="icon-button active" onClick={this.OnProfileClick}>
                            <img
                                className="fa user-profile-photo"
                                onClick={this.OnProfileClick}
                                src="https://design-system.azureedge.net/images/icons/DefaultProfileImg32.png"
                                alt="profile"
                            />
                        </div>
                        {authentication.getUser() && (
                            <ProfileSection showProfile={this.state.showProfile}
                                            userPermissions={this.props.userPermissions}/>
                        )}
                    </div>
                </div>
            </nav>
        )
    }

    public OnSequencerClick = () => {
        window.location.href = window.location.protocol + "//" + window.location.host + "/sequencer/sequencer/transactions"
    }

    public OnNavigateClick = () => {
        this.setState({
            showBrainMenu: false,
            showProfile: false,
            showSequencerMenu: false
        })
    }

    public OnBrainMenuClick = () => {
        this.setState({
            showBrainMenu: !this.state.showBrainMenu,
            showProfile: false,
            showSequencerMenu: false
        })
    }

    public OnSequencerMenuClick = () => {
        this.setState({
            showSequencerMenu: !this.state.showSequencerMenu,
            showBrainMenu: false,
            showProfile: false
        })
    }

    public OnProfileClick = () => {
        this.setState({
            showProfile: !this.state.showProfile,
            showBrainMenu: false,
            showSequencerMenu: false
        })
    }
}
