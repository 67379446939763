import * as React from 'react';

export interface IInputFormGroupProps {
    labelText?: string;
    name?: string;
    value?: any;
    placeholder?: string;
    formGroupClassName?: string;
    onChange?: (e: any) => void;
}

export const InputFormGroup = (props: IInputFormGroupProps) => {
    return (
        <div className={props.formGroupClassName}>
            <div className="form-group">
                <label>{props.labelText}</label>
                <input className="form-control" type="text" 
                    name={props.name} 
                    value={props.value} 
                    onChange={props.onChange} 
                    placeholder={props.placeholder}/>
            </div>
        </div>
    );
}