import {ApiServiceBase} from "../../common/ApiServiceBase";
import {IDropDownItem} from "../../common";
import {ICustomerGroup, ICustomerGroupDifferences} from "./models";

const CUSTOMER_GROUPS_URL = '/customer-groups';

export default class CustomerGroupApi extends ApiServiceBase {
    public getCustomerGroup(customerGroupName: string): Promise<ICustomerGroup> {
        return Promise.all([
            this.get(CUSTOMER_GROUPS_URL + '/' + customerGroupName)
        ])
            .then(items => items[0]);
    }

    public getCustomerGroupsAsDropDown(): Promise<IDropDownItem[]> {
        return this.getDropDownItems(CUSTOMER_GROUPS_URL,
            {key: "", text: "Select customer group", disabled: true}
        );
    }

    public getCustomerGroupDifferences(customerGroupName: string): Promise<ICustomerGroupDifferences[]> {
        return this.get(CUSTOMER_GROUPS_URL + '/' + customerGroupName + '/differences')
            .then((result: ICustomerGroupDifferences[]) => result);
    }

    public getCustomersDifferences(customerGroupName: string, customers: string[]): Promise<ICustomerGroupDifferences[]> {
        return this.postData(
            CUSTOMER_GROUPS_URL + '/' + customerGroupName + '/differences',
            undefined,
            customers
        ).then((result: ICustomerGroupDifferences[]) => result);
    }

    public synchronizeCustomerWithGroup(customerGroupName: string, customer: string): Promise<boolean> {
        return this.post(CUSTOMER_GROUPS_URL + '/sync/' + customer + '/' + customerGroupName)
            .then((result: Response) => result.ok)
            .catch(reason => false);
    }

    public addCustomerToGroup(customerGroupName: string, customer: string): Promise<boolean> {
        return this.post(CUSTOMER_GROUPS_URL + '/' + customer + '/' + customerGroupName)
            .then((result: Response) => result.ok)
            .catch(reason => false);
    }

    public synchronizeCustomerAssignment
    (customerGroupName: string, assignedCustomers: string[], unassignedCustomers: string[]): Promise<boolean> {
        return this
            .postData(`${CUSTOMER_GROUPS_URL}/${customerGroupName}/customers`, undefined, assignedCustomers)
            .then((result: Response) => {
                    if (result.ok) {
                        return this
                            .deleteData(`${CUSTOMER_GROUPS_URL}/${customerGroupName}/customers`, undefined, unassignedCustomers)
                            .then((deleteResponse: Response) => deleteResponse.ok)
                            .catch(reason => false)
                    } else {
                         return false;
                    }
                }
            )
            .catch(reason => false);
    }
}