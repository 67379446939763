import * as React from 'react';
import { IProcessStepInstanceFailureItem, IProcessStepInstanceFileReference, IProcessStepItem, IBossMessage } from './processDetailsModel';
import Accordion from '../../common/Accordion';
import '../../common/styles/stepSection.css';
import { PERMISSIONS_RESEND_MESSAGE } from 'src/common/UserPermissions';
import { IUserPermissions } from 'src/main/main';

export interface IStepDetailsSectionProps {
    item: any
    step: IProcessStepItem;
    processState: string;
    downloadFile: (e: any) => void;
    resendStart: (e: any) => void;
    hideResendPanel: (e: any) => void;
    userPermissions: IUserPermissions;
}

export class StepDetailsSection extends React.Component<IStepDetailsSectionProps, any> {
    constructor(props: any) {
        super(props);
        this.downloadFile = this.downloadFile.bind(this);
        this.resendStart = this.resendStart.bind(this);
        this.hideResendPanel = this.hideResendPanel.bind(this);
    }

    public render() {
        const isResendPossible = this.hasPermissions(PERMISSIONS_RESEND_MESSAGE) &&
            this.props.step.stepState === 'Failed';
        return (
            <div>
                <form className={"filter-search-criteria"}>
                    <fieldset>
                        <h3 className="title-blue-underline">{this.props.item.name}</h3>
                        <div>
                            <div className={"grid-wrapper filter-body"}>
                                <table>
                                    <tbody>
                                        <tr>
                                            <td><b>Name</b></td>
                                            <td><span className="regular-body-text">{this.props.item.name}</span></td>
                                        </tr>
                                        <tr>
                                            <td><b>Message Type</b></td>
                                            <td><span className="regular-body-text">{this.props.step.messageType}</span></td>
                                        </tr>
                                        <tr>
                                            <td><b>Session Finalizer</b></td>
                                            <td><span className="regular-body-text">{this.props.step.sessionFinalizer}</span></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <h3 className="title-blue-underline">Systems</h3>
                        <div>
                            <div className={"grid-wrapper filter-body"}>
                                <table className={"responsive-table"}>
                                    <tbody>
                                        <tr>
                                            <td><b>Name</b></td>
                                            <td><b>Action</b></td>
                                            <td><b>Description</b></td>
                                            <td><b>Runs</b></td>
                                        </tr>
                                        {
                                            this.props.step.stepInstanceSystems.map((system: any) => {
                                                return (
                                                    <tr key={system.system}>
                                                        <td>{system.system}</td>
                                                        <td>
                                                            {!system.action || 0 === system.action ? (<span className="regular-inactive-text"><i>Empty</i></span>) : system.action}
                                                        </td>
                                                        <td>
                                                            {system.actionDescription}
                                                        </td>
                                                        <td>
                                                            {this.renderRuns(system)}
                                                        </td>
                                                    </tr>
                                                )
                                            })
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        {
                            this.props.step.stepInstanceFailures.length > 0 ?
                                (
                                    <div>
                                        <h3 className="title-blue-underline">Errors</h3>
                                        <div>
                                            <div className={"grid-wrapper filter-body"}>
                                                {
                                                    this.props.step.stepInstanceFailures.map((failure: IProcessStepInstanceFailureItem) => {
                                                        return (
                                                            <Accordion
                                                                title={"Action name: " + failure.ActionName}
                                                                key={failure.StartTime}
                                                                titleClassName="error-bottom-color error-color"
                                                                toggleClassName="error-color">
                                                                <div>
                                                                    <table className="table-error">
                                                                        <tbody>
                                                                            <tr>
                                                                                <td>
                                                                                    Time (UTC):
                                                                        </td>
                                                                                <td>
                                                                                    <p>{failure.StartTime}</p>
                                                                                </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td>
                                                                                    Http Code:
                                                                        </td>
                                                                                <td>
                                                                                    <p>{failure.HttpStatusCode}</p>
                                                                                </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td>
                                                                                    Http Body:
                                                                        </td>
                                                                                <td>
                                                                                    <p>{failure.HttpBody}</p>
                                                                                </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td>
                                                                                    Workflow:
                                                                        </td>
                                                                                <td>
                                                                                    <p>{failure.Workflow}</p>
                                                                                </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td>
                                                                                    Run ID:
                                                                        </td>
                                                                                <td>
                                                                                    <p>{failure.RunId}</p>
                                                                                </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td>
                                                                                    Message property:
                                                                        </td>
                                                                                <td>
                                                                                    {this.renderMessageBody(failure.Body, failure.BossMessage)}
                                                                                </td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                            </Accordion>
                                                        )
                                                    })
                                                }
                                            </div>
                                        </div>
                                    </div>
                                ) :
                                (<div />)
                        }
                        {
                            this.props.step.fileReferences.length > 0 ?
                                (<div>
                                    <h3 className="title-blue-underline">File references</h3>
                                    <div>
                                        <div className={"grid-wrapper filter-body"}>
                                            <table>
                                                <tbody>
                                                    <tr>
                                                        <td><b>File name</b></td>
                                                    </tr>
                                                    {
                                                        this.props.step.fileReferences.map((fileReference: IProcessStepInstanceFileReference) => {
                                                            return (
                                                                <tr key={fileReference.name}>
                                                                    <td><span className="regular-body-text"><a href={"#"} onClick={this.downloadFile} data-step-instance-id={this.props.step.id} data-url={fileReference.url} data-name={fileReference.name} data-system={fileReference.system}>{fileReference.name}</a></span></td>
                                                                </tr>
                                                            )
                                                        })
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>)
                                :
                                (<div />)
                        }
                    </fieldset>
                    <fieldset>
                        <div className="filter-button-area">
                            {isResendPossible ? (<button className="button button-blue" type="button" onClick={this.resendStart}>Resend</button>) : ('')}
                            <a href={"#"} onClick={this.hideResendPanel} className="regular-link-text clear-link">Cancel</a>
                        </div>
                    </fieldset>
                </form>
            </div>
        );
    }

    private renderRuns(system: any) {
        if (system.runs) {
            return system.runs.map((run: string) => {
                return (
                    <div key={run}>
                        {run}
                        <br />
                    </div>
                );
            })
        }
        else {
            return (
                <div />
            );
        }
    }

    private renderMessageBody(body: string, message?: IBossMessage) {
        if (message) {
            return (
                <div>
                    <p>Correlation ID: {message.CorrelationId}</p>
                    <p>Customer: {message.Customer}</p>
                    <p>Message Type: {message.MessageType}</p>
                    <p>System: {message.System}</p>
                    <h3 className="title-blue-underline">Files:</h3>
                    {message.Files.map((file) => {
                        return (
                            <div key={file.Url}>
                                <div className="files-border">
                                    <ul>
                                        <li>Content Type: {file.ContentType}</li>
                                        <li><span className="regular-body-text"><a href={"#"} onClick={this.downloadFile} data-step-instance-id={this.props.step.id} data-url={file.Url} data-name={file.Name} data-system={file.System}>{file.Url}</a></span></li>
                                        <li>Correlation ID: {file.CorrelationId}</li>
                                        <li>Unique ID: {file.UniqueId}</li>
                                        <li>MessageType: {file.MessageType}</li>
                                        <li>System: {file.System}</li>
                                    </ul>
                                </div>
                                <br />
                            </div>
                        )
                    })}
                </div>
            );
        }
        else {
            return (
                <div>
                    {body}
                </div>
            );
        }
    }

    private downloadFile(e: any) {
        this.props.downloadFile(e);
    }

    private resendStart(e: any) {
        this.props.resendStart(e);
    }

    private hideResendPanel(e: any) {
        this.props.hideResendPanel(e);
    }

    private hasPermissions(permission: string): boolean {
        return this.props.userPermissions.businessFunctions.indexOf(permission) !== -1
    }
}