interface IApiConfig {
    apiUrl: string;
}

export class GlobalConfig {

    public static getApiUrl(): string {
        return this.apiConfig.apiUrl;
    }

    public static setApiConfig(apiConfig: any) {
        this.apiConfig = apiConfig;
    }

    private static apiConfig: IApiConfig = {
        apiUrl: ''
    }
}
