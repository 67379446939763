import * as React from 'react';
import {RejectedMessagesReasonItem} from './RejectedMessagesReasonItem';
import {StripHeader, StripBody} from '../../common';
import * as moment from 'moment';
import '../../common/styles/resultSection.css';
import {ResultItem} from "../../common/ResultItem";
import {MonitorListItem} from "../../common/monitor/components/MonitorListItem";
import {IRejectedMessageEntry} from "../model/RejectedMessagesModel";
import {IMonitorListEntryProps} from "../../common/monitor/model/monitor";



export class RejectedMessagesListItem extends MonitorListItem<IRejectedMessageEntry> {

    constructor(props: IMonitorListEntryProps<IRejectedMessageEntry>) {
        super(props);
    }

    protected renderHeader() {
        const resultItemClassName = 'col-25';
        return (
            <StripHeader>
                <div className="result-header-bar">
                    <ResultItem title="Correlation Id" value={this.props.entry.CorrelationId} className={resultItemClassName}/>
                    <RejectedMessagesReasonItem displayName={this.props.entry.RejectionReason}/>
                </div>
            </StripHeader>
        )
    }

    protected renderBody() {
        const resultItemClassName = 'col-25';
        const dateTimeFormat: string = 'DD-MM-YYYY HH:mm:ss';
        return (
            <StripBody>
                <div className="grid-wrapper">
                    <ResultItem title="Unique Id" value={this.props.entry.UniqueId} className={resultItemClassName}/>
                    <ResultItem title="Status" value="Rejected at BOSS" className={resultItemClassName}/>
                    <ResultItem title="Created"
                                value={this.props.entry.CreatedDate ? moment(this.props.entry.CreatedDate).format(dateTimeFormat) : ''}
                                className={resultItemClassName} />
                </div>
                <div className="grid-wrapper">
                    <ResultItem title="Customer" value={this.props.entry.CustomerName} className={resultItemClassName}/>
                    <ResultItem title="System" value={this.props.entry.SystemName} className={resultItemClassName}/>
                </div>
            </StripBody>
        );
    }
}