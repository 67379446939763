import * as React from 'react';
import { withRouter } from 'react-router-dom';

const BackButton = (props: any) => {

    const onBackButtonClick = () => {
        props.history.goBack();
    }

    return(
        <button className="button-large button-transparent back-btn" onClick={onBackButtonClick} >
            <i className="fa fa-angle-left" aria-hidden="true" />
        </button>
    )
}

export default (withRouter)(BackButton);