import * as React from 'react';
import * as moment from "moment";
import {bindActionCreators, compose} from "redux";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import * as monitorActions from "../actions/rejectedMessagesActions";
import {RejectedMessagesList} from "./RejectedMessagesList";
import {IRootReducer} from "../../store/rootReducer";
import {DetectScrollToBottom, LoadingAnimation, PageHeader} from "../../common";
import {MonitorContainer} from "../../common/monitor/components/MonitorContainer";
import {IMonitorContainer, IMonitorContainerState} from "../../common/monitor/model/monitor";
import {RejectedMessagesFilterSection} from "./RejectedMessagesFilterSection";
import {IMonitorFilterState} from "../../common/monitor/model/filtering";


class RejectedMessagesContainer extends MonitorContainer<IMonitorContainer, IMonitorContainerState> {

    constructor(props: IMonitorContainer) {
        super(props);
        this.path = 'rejected-messages';
        this.detailsPath = `${this.path}/details/`
        this.loadItems = this.loadItems.bind(this);
        this.loadFilterDictionaries = this.loadFilterDictionaries.bind(this);
        this.onScrollToBottom = this.onScrollToBottom.bind(this);
    }

    public componentDidMount() {
        this.loadFilterDictionaries();
        this.loadItems();
    }


    public onScrollToBottom() {
        if (this.props.nextPageToken && this.props.nextPageToken.length > 0 && !this.props.isLoading) {
            this.updateFilters({NextPageToken: this.props.nextPageToken}, () => {
                this.loadItems();
            });
        }
    }

    public render() {
        return (
            <div>
                <DetectScrollToBottom onScrollToBottom={this.onScrollToBottom} />
                <PageHeader title="Rejected Messages View"/>
                {this.props.isLoading && <LoadingAnimation/>}

                <RejectedMessagesFilterSection
                    correlationId={this.props.filter.CorrelationId}
                    uniqueId={this.props.filter.UniqueId}
                    systemsList={this.props.filter.systemsList}
                    selectedSystem={this.props.filter.System}
                    rejectionReasons={this.props.filter.rejectionReasons}
                    selectedRejectionReason={this.props.filter.RejectionType}
                    onApply={this.onFilterApply}
                    onChange={this.onFilterChange}
                    onClear={this.onFilterClear}
                    fromCurrentDate={this.props.filter.DateFrom}
                    toCurrentDate={this.props.filter.DateTo}
                    fromMaxDate={moment(undefined)}
                    toMaxDate={moment(undefined)}
                    moreFilters={this.props.monitorMoreFilters}
                    onMoreFilters={this.onMonitorMoreFiltersChanged}
                    showDownloadAll={false}
                />
                <RejectedMessagesList monitorEntries={this.props.monitorMessages} onEntrySelect={this.onItemSelect}/>
            </div>
        );
    }

    protected createEmptyFilterState(): Partial<IMonitorFilterState> {
        return {
            CorrelationId: '',
            System: '',
            UniqueId: '',
            RejectionType: '',
            NextPageToken: '',
            DateFrom: moment().startOf('day'),
            DateTo: moment().startOf('hour').add(1, 'hour')
        }
    }

    protected loadItems(): void {
        const filter: IMonitorFilterState = Object.assign({}, this.props.filter);
        this.props.actions.loadRejectedMessages(filter);
    }

    protected loadFilterDictionaries() {
        const filter: IMonitorFilterState = Object.assign({}, this.props.filter);
        this.props.actions.loadFilterDictionaries(filter);
    }

}


const mapStateToProps = (state: IRootReducer, ownProps: any): Partial<IMonitorContainer> => {

    return {
        isLoading: state.monitorView.isLoading,
        errorLoading: state.monitorView.errorLoading,
        loadingErrorMessage: state.monitorView.loadingErrorMessage,
        monitorMessages: state.monitorView.monitorMessages,
        filter: state.monitorView.filter || {},
        nextPageToken: state.monitorView.nextPageToken,
        monitorMoreFilters: state.monitorView.monitorMoreFilters
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        actions: bindActionCreators(monitorActions, dispatch)
    };
}

export default compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps)
)(RejectedMessagesContainer);
