import * as React from 'react';
import * as moment from 'moment';
import { FilterSection, InputFormGroup, DropDownFormGroup, IFilterSectionProps, DatePickerSection, IDropDownItem, CheckboxFormGroup } from '../common/';


interface IOperationalFilterSectionProps extends IFilterSectionProps {
    carrierBookingRequestId?: string;
    correlationId?: string;
    uniqueId?: string;
    selectedProcess?: string;
    selectedCapability?: string;
    selectedCustomer?: string;
    selectedProcessStatus?: string;
    processesList: IDropDownItem[];
    processStatuses: IDropDownItem[];
    capabilitiesList: IDropDownItem[];
    customers: IDropDownItem[];
    fromNumberOfDays?: number;
    toNumberOfDays?: number;
    fromCurrentDate?: moment.Moment;
    toCurrentDate?: moment.Moment;
    fromMaxDate?: moment.Moment;
    toMaxDate?: moment.Moment;
    moreFilters?: boolean;
    onChange?: (e: any) => void;
    onClear?: () => void;
    onDownloadAll?: () => void;
    onMoreFilters?: () => void;
}


export class ProcessesFilterSection extends React.Component<IOperationalFilterSectionProps> {
    constructor(props: IOperationalFilterSectionProps) {
        super(props);

        this.onClear = this.onClear.bind(this);
        this.onChange = this.onChange.bind(this);
        this.onDownloadAll = this.onDownloadAll.bind(this);
    }

    public render () {
        const formGroupClassName = "col-25";

        return (
            <FilterSection  onApply={this.props.onApply} onClear={this.onClear} quickFilterTitle="Correlation ID" showDownloadAll={true} onDownloadAll={this.onDownloadAll}
            quickFilterValue={this.props.correlationId} onChange={this.onChange} moreFilters={this.props.moreFilters} onMoreFilters={this.props.onMoreFilters}>
                <div className="grid-wrapper">
                    <InputFormGroup 
                        labelText="Correlation ID" 
                        name="correlationId"
                        value={this.props.correlationId}
                        placeholder="Correlation ID"
                        formGroupClassName={formGroupClassName}
                        onChange={this.onChange}
                    />
                    
                    <DropDownFormGroup 
                        name={"capability"}
                        labelText="Capability"
                        formGroupClassName={formGroupClassName}
                        elements={this.props.capabilitiesList}
                        onChange={this.onChange}
                        value={this.props.selectedCapability}
                    />

                    <DropDownFormGroup 
                        name={"process"}
                        labelText="Process Name" 
                        formGroupClassName={formGroupClassName}
                        elements={this.props.processesList}
                        onChange={this.onChange}
                        value={this.props.selectedProcess}
                    />
                </div>
                <div className="grid-wrapper">
                    <InputFormGroup 
                        name={"uniqueId"}
                        labelText="Unique ID" 
                        placeholder="Unique ID"
                        formGroupClassName={formGroupClassName}
                        onChange={this.onChange}
                        value={this.props.uniqueId}
                    />
                    <DropDownFormGroup 
                        name="customer"
                        labelText="Customer" 
                        formGroupClassName={formGroupClassName}
                        elements={this.props.customers}
                        onChange={this.onChange}
                        value={this.props.selectedCustomer}
                    />
                    
                    <DropDownFormGroup
                        name="processStatus"
                        labelText="Process Status" 
                        formGroupClassName={formGroupClassName}
                        elements={this.props.processStatuses}
                        onChange={this.onChange}
                        value={this.props.selectedProcessStatus}
                    />
                </div>
                <div className="grid-wrapper">
                    <DatePickerSection 
                        id="create-process-from"
                        name="createDateFrom"
                        placeholderText="Date From"
                        labelText={"Date From"}
                        formGroupClassName={formGroupClassName}
                        numberOfDays={this.props.fromNumberOfDays}
                        onDateSelect={this.onChange}
                        selectedDate={this.props.fromCurrentDate}
                        maxDate={this.props.fromMaxDate}
                        showTimeSelect={true}
                        timeInterval={15}
                        dateFormat="DD-MM-YYYY HH:mm"
                    />

                    <DatePickerSection 
                        id="create-process-to"
                        name="createDateTo"
                        placeholderText="Date To"
                        labelText={"Date To"}
                        formGroupClassName={formGroupClassName}
                        numberOfDays={this.props.toNumberOfDays}
                        onDateSelect={this.onChange}
                        selectedDate={this.props.toCurrentDate}
                        maxDate={this.props.toMaxDate}
                        showTimeSelect={true}
                        timeInterval={15}
                        dateFormat="DD-MM-YYYY HH:mm"
                    />
                    <InputFormGroup 
                        name={"carrierBookingRequestId"}
                        labelText="Carrier Booking Request Id" 
                        placeholder="Carrier Booking Request Id"
                        formGroupClassName={formGroupClassName}
                        onChange={this.onChange}
                        value={this.props.carrierBookingRequestId}
                    />
                </div>
                <div className="grid-wrapper">
                    <CheckboxFormGroup
                        name={"autoRefresh"}
                        labelText="Auto refresh" 
                        placeholder="Auto refresh"
                        formGroupClassName={formGroupClassName}
                        onChange={this.onChange}
                        value={"true"}
                    />
                </div>
            </FilterSection>
        );
    }

    private onClear() {
        if (this.props.onClear) {
            this.props.onClear();
        }
    }

    private onChange(e: any) {
        if (this.props.onChange) {
            this.props.onChange(e);
        }
    }

    private onDownloadAll(e: any) {
        if (this.props.onDownloadAll) {
            this.props.onDownloadAll();
        }
    }
}
