import { GroupsApiService } from "./GroupsApiService";
import { ICustomer, IProductGroup, IProduct } from "./GroupModels";
import * as actionTypes from "./GroupsActionTypes";
import { IDropDownItem } from "src/common";

const api = new GroupsApiService();

export const loadError = () => {
  return {
    type: actionTypes.DATA_LOAD_ERROR,
    result: { errorLoading: true },
  };
};

export const loadInProgress = () => {
  return { type: actionTypes.DATA_LOAD_IN_PROGRESS };
};

export const loadAvailableCustomersSuccess = (
  availableCustomers: ICustomer[]
) => {
  return {
    type: actionTypes.LOAD_AVAILABLE_CUSTOMERS_SUCCESS,
    availableCustomers,
  };
};

export const loadAvailableGroupsSuccess = (
  availableGroups: IProductGroup[]
) => {
  return {
    type: actionTypes.LOAD_AVAILABLE_GROUPS_SUCCESS,
    availableGroups,
  };
};

export const saveError = () => {
  return { type: actionTypes.GROUP_SAVE_ERROR };
};

export const loadGroupSuccess = (group: IProductGroup) => {
  return { type: actionTypes.LOAD_GROUP_SUCCESS, group };
};

export const loadProductSuccess = (product: IProduct) => {
  return { type: actionTypes.LOAD_PRODUCT_SUCCESS, product };
};

export const loadProductsSuccess = (products: IDropDownItem[]) => {
  return { type: actionTypes.LOAD_PRODUCTS_SUCCESS, products };
};

export const saveInProgress = () => {
  return { type: actionTypes.SAVE_IN_PROGRESS };
};

export const saveProductSuccess = () => {
  return { type: actionTypes.SAVE_PRODUCT_SUCCESS };
};

export const saveProductError = () => {
  return { type: actionTypes.SAVE_PRODUCT_ERROR };
};

export const loadProducts = () => {
  return (dispatch: any) => {
    dispatch(loadInProgress());
    api
      .getProducts()
      .then((products) => {
        dispatch(loadProductsSuccess(products));
      })
      .catch((error) => {
        dispatch(loadError());
      });
  };
};

export const loadGroup = (groupName: string) => {
  return (dispatch: any) => {
    dispatch(loadInProgress());
    api
      .getGroup(groupName)
      .then((group) => {
        dispatch(loadGroupSuccess(group));
      })
      .catch((error) => {
        dispatch(loadError());
      });
  };
};

export const loadAvailableCustomers = () => {
  return (dispatch: any) => {
    dispatch(loadInProgress());
    api
      .getAllCustomers()
      .then((customers) => {
        dispatch(loadAvailableCustomersSuccess(customers));
      })
      .catch((error) => {
        dispatch(loadError());
      });
  };
};

export const loadAvailableGroups = () => {
  return (dispatch: any) => {
    dispatch(loadInProgress());
    return api
      .getAllGroups()
      .then((groups) => {
        dispatch(loadAvailableGroupsSuccess(groups));
      })
      .catch((error) => {
        dispatch(loadError());
      });
  };
};

export const saveGroup = (group: IProductGroup) => {
  return (dispatch: any) => {
    dispatch(saveInProgress());
    return api
      .putGroup(group)
      .then((item) => {
        dispatch(loadGroupSuccess(group));
      })
      .catch((error) => {
        dispatch(saveError());
        throw error.message;
      });
  };
};

export const deleteGroup = (group: IProductGroup) => {
  return (dispatch: any) => {
    dispatch(saveInProgress());
    return api
      .deleteGroup(group)
      .then()
      .catch((error) => {
        dispatch(saveError());
        throw error.message;
      });
  };
};

export const loadProduct = (productName: string) => {
  return (dispatch: any) => {
    dispatch(loadInProgress());
    return api
      .getProduct(productName)
      .then((product) => {
        dispatch(loadProductSuccess(product));
      })
      .catch((error) => {
        dispatch(loadError());
      });
  };
};

export const saveProduct = (product: IProduct) => {
  return (dispatch: any) => {
    dispatch(saveInProgress());
    return api
      .putProduct(product)
      .then((item) => {
        dispatch(saveProductSuccess());
        dispatch(loadProduct(product.id));
      })
      .catch((error) => {
        dispatch(saveError());
        throw error.message;
      });
  };
};
