import * as React from "react"
import { IActivity } from "./UserActivityApi"
import { StripsContainer, Strip, StripHeader, StripBody } from "../common"
import { ResultItem } from "./ResultItem"
import { ActionItem } from "./ActionItem"
import * as moment from "moment"
import "./styles/resultSection.css"
import { USERACTIVITY_ACTION_TYPE_RESEND_MESSAGE, USERACTIVITY_ACTION_TYPE_ACTIVITY_CHANGE } from "./userActivityActionsTypes"

interface IUserActivityResultProps {
	activities: IActivity[]
}

export const UserActivityResult: React.SFC<IUserActivityResultProps> = (props: IUserActivityResultProps) => {
	const dateTimeFormat: string = "DD-MM-YYYY HH:mm:ss"
	const processesNotEmpty: boolean = props.activities && props.activities.length > 0
	const resultItemClassName = "col-25"
	return (
		<div>
			{processesNotEmpty && (
				<StripsContainer>
					{props.activities ? (
						props.activities.map((p: IActivity, index) => (
							<Strip key={p.CorrelationId + "_" + index}>
								<StripHeader>
									<div className="result-header-bar">
										<ResultItem title="User" value={`${p.UserName} (${p.UserEmail})`} className="" />
										<ActionItem action={p.ActivityType} actionDisplay={p.ActivityTypeDisplay} />
									</div>
								</StripHeader>
								<StripBody>
									<div className="grid-wrapper">
										<ResultItem title="Comments" value={p.Comments} />
									</div>
									<div className="grid-wrapper">
										<ResultItem title="Customer" value={p.Customer} className={resultItemClassName} />
										{p.ActivityType === USERACTIVITY_ACTION_TYPE_ACTIVITY_CHANGE ? (
											<>
												<ResultItem title="Capability" value={p.Capability} className={resultItemClassName} />
												<ResultItem title="Process name" value={p.Process} className="col-50" />
											</>
										) : (
											<>
												<ResultItem title="Message type" value={p.MessageType} className={resultItemClassName} />
												<ResultItem title="System" value={p.System} className={resultItemClassName} />
											</>
										)}
									</div>
									{p.ActivityType === USERACTIVITY_ACTION_TYPE_RESEND_MESSAGE && (
										<div className="grid-wrapper">
											<ResultItem title="Unique Id" value={p.UniqueId} className={resultItemClassName} />
											<ResultItem title="Correlation Id" value={p.CorrelationId} className={resultItemClassName} />
											{p.OriginalCorrelationId && (
												<ResultItem
													title="OriginalCorrelation Id"
													value={p.OriginalCorrelationId}
													className={resultItemClassName}
												/>
											)}
										</div>
									)}
									<div className="grid-wrapper">
										<ResultItem
											title="Created"
											value={p.DateTime ? moment(p.DateTime).format(dateTimeFormat) : ""}
											className={resultItemClassName}
										/>
									</div>
								</StripBody>
							</Strip>
						))
					) : (
						<div />
					)}
				</StripsContainer>
			)}
		</div>
	)
}
