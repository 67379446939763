import * as React from "react"
import { USERACTIVITY_ACTION_TYPE_RESEND_MESSAGE, USERACTIVITY_ACTION_TYPE_ACTIVITY_CHANGE } from "./userActivityActionsTypes"

interface IActionProps {
	action?: number
	actionDisplay?: string
}

export const ActionItem = (props: IActionProps) => {
	let statusIcon = ""
	const spanStyle = {
		color: "#64B2D4"
	}
	switch (props.action) {
		case USERACTIVITY_ACTION_TYPE_RESEND_MESSAGE:
			statusIcon = "fa fa-redo"
			spanStyle.color = "#64B2D4"
			break
		case USERACTIVITY_ACTION_TYPE_ACTIVITY_CHANGE:
			statusIcon = "fa fa-hourglass-start"
			spanStyle.color = "#A3DCAF"
			break
	}

	return (
		<span style={spanStyle}>
			<i className={statusIcon} />
			&nbsp;{props.actionDisplay}
		</span>
	)
}
