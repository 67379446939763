import * as React from 'react';
import DatePicker from 'react-datepicker';
import * as moment from 'moment';
import 'moment/locale/en-gb';
import './DatePickerSection.css';

interface IDatePickerProps{
    id: string;
    placeholderText: string;
    labelText?: string;
    formGroupClassName? : string;
    name?: string;
    numberOfDays?: number;
    selectedDate?: moment.Moment;
    minDate?: moment.Moment;
    maxDate?: moment.Moment;
    popperPlacement?: string;
    showTimeSelect?: boolean;
    timeFormat?: string;
    timeInterval?: number;
    dateFormat?: string;
    onDateSelect?: (e: any) => void;
}

export class DatePickerSection extends React.Component<IDatePickerProps, any> {

    private calendarDataPicker: any;
    private defaultDateFormat = "DD-MM-YYYY";
    constructor(props: IDatePickerProps) {
        super(props);
        this.state = {
            showMonSun: false,
            currentDate: this.props.selectedDate,
            dateFormat: this.props.dateFormat || this.defaultDateFormat
        };

        this.dateBlur = this.dateBlur.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.clearDatePicker = this.clearDatePicker.bind(this);
        this.toggleCalendar = this.toggleCalendar.bind(this);
    }

    public dateBlur = (event: any) => {
        const enteredDate = moment(event.target.value,this.state.dateFormat,true);
        if(!enteredDate.isValid())
        {
            this.setState({
                currentDate: this.state.currentDate
            }, () => {
                this.calendarDataPicker.setSelected(this.state.currentDate,event,false);
            });
        } else {
            this.setState({
                currentDate: enteredDate
            }, () => {
                this.calendarDataPicker.setSelected(enteredDate,event,false);
                if (this.props.onDateSelect && this.state.currentDate) {
                    this.props.onDateSelect({
                        target: {
                            name: this.props.name,
                            value: this.state.currentDate.format()
                        }
                    });
                }

            });
        }
    };

    public handleChange = (date: any) => {
        this.setState({
            currentDate: date,
            showMonSun: false
        }, () => {
            if (this.props.onDateSelect && this.state.currentDate) {
                this.props.onDateSelect({
                    target: {
                        name: this.props.name,
                        value: this.state.currentDate.format()
                    }
                });
            }
        });
    };

    public clearDatePicker = (event: any) => {
        event.preventDefault();
        event.persist();
        this.setState({
            currentDate: null
        }, () => {
            this.calendarDataPicker.setSelected(null,event,false);
        });
        this.calendarDataPicker.setOpen(false);
    };

    public toggleCalendar = (event: any) => {
        const dateField = event.currentTarget.parentNode.firstChild.firstChild.firstChild.firstChild;
        this.setState({
            showMonSun: !this.state.showMonSun
        },() => {
            (this.state.showMonSun) ? dateField.focus() : dateField.blur();
        });
    };

    public componentWillReceiveProps(next: IDatePickerProps) {
        if (next.dateFormat !== this.props.dateFormat) {
            this.setState({
                dateFormat: next.dateFormat
            });
        }
        if(next.selectedDate !== this.state.currentDate){
            this.setState({
                currentDate: next.selectedDate
            });
        }
    }

    public render() {
        let minDate = this.props.minDate;
        let maxDate = this.props.maxDate;
        if (this.props.numberOfDays) {
            if (this.props.numberOfDays < 0) {
                maxDate = this.props.maxDate || moment();
                minDate = moment(maxDate).add(this.props.numberOfDays, 'days');
            }
            else {
                minDate = this.props.minDate || moment();
                maxDate = moment(minDate).add(this.props.numberOfDays, 'days');
            }
        }
        return (
            <div className={this.props.formGroupClassName}>
                <div className="form-group">
                    { this.props.labelText && this.props.labelText.length > 0 &&
                        <label>{ this.props.labelText }</label>
                    }
                    <div className="input-btn-wrapper">
                        <DatePicker
                            id={this.props.id}
                            dateFormat={this.state.dateFormat}
                            selected={this.state.currentDate}
                            onChange={this.handleChange}
                            onBlur={this.dateBlur}
                            ref={(calendar: any) => this.calendarDataPicker = calendar}
                            locale="en-gb"
                            className="form-control"
                            popperPlacement={this.props.popperPlacement || "top-end"}
                            minDate={minDate}
                            maxDate={maxDate}
                            placeholderText={this.props.placeholderText}
                            showTimeSelect={this.props.showTimeSelect || false}
                            timeFormat={this.props.timeFormat || 'HH:mm'}
                            timeIntervals={this.props.timeInterval || 60}
                        >
                            <div className={"date-picker-footer-container"}>
                                <a href={"javascript:void(0)"} className={"date-picker-footer"}
                                onClick={this.clearDatePicker}>Clear</a>
                            </div>
                        </DatePicker>
                        <button type="button" className="select-btn" style={{position: "relative"}}
                                onClick={this.toggleCalendar}>
                            <span className="fa fa-calendar"/>
                        </button>
                    </div>
                </div>
            </div>
        );
    }
}