const spaciousStyles = {
    height: 'auto',
    minHeight:'50px',
    whiteSpace:'normal',
    display: 'flex',
    alignItems: 'center'
};

const thinStyles = {
    display: 'flex',
    alignItems: 'center'
};

export enum ColumnTheme {
    SPACIOUS = 'spacious',
    THIN = 'thin'
}

export const setStyles = (theme: ColumnTheme | undefined) => {
    switch (theme) {
        case ColumnTheme.SPACIOUS:
            return spaciousStyles;
        case ColumnTheme.THIN:
            return thinStyles;
        default:
            return spaciousStyles;
    }
};

export const generalHeaderStyles = {
    height: 16,
    display: 'flex'
};
