import * as React from 'react';
import Select from 'react-select';

export interface IDropDownFormGroupProps {
    name?: string;
    labelText?: string;
    value?: any;
    formGroupClassName?: string;
    elements?: IOptionElement[];
    onChange?: (e: any) => void;
}

export interface IOptionElement {
    key: string;
    text: string;
    disabled?: boolean;
}

export interface IDropDownItem {
    key: string,
    text: string,
    disabled?: boolean
}

interface IDropDownFormGroupState {
    selectedOption: {label:string, value:string} | null,
    options: Array<{label:string, value:string}>
}

export class DropDownFormGroup extends React.Component<IDropDownFormGroupProps, IDropDownFormGroupState> {
    constructor(props: IDropDownFormGroupProps) {
        super(props);

        let optionsList: Array<{label:string, value:string}> = []
        if (props.elements) {
            optionsList = props.elements.map((element) => 
                ({label: element.text, value: element.key})
            );
        }
        this.state = {
            selectedOption: optionsList.find(item => item.value === props.value) ?? { label: props.value, value: props.value },
            options: optionsList
        };
    }

    public componentWillReceiveProps(next: IDropDownFormGroupProps) {
        if ((this.props.elements == null || this.props.elements.length === 0) && next.elements)
        {
            const optionsList: Array<{label:string, value:string}> = next.elements.map((element) => 
                ({label: element.text, value: element.key})
            );
            this.setState(
                {
                    options: optionsList,
                    selectedOption: optionsList.find(item => item.value === next.value) ?? { label: next.value, value: next.value },
                });
        }
    }

    public handleChange = (selectedOption:{label:string, value:string}) => {
        this.setState(
          { selectedOption }
        );
        if (this.props.onChange){
            this.props.onChange({
                target: {
                    name: this.props.name,
                    value: selectedOption.value
                },
                currentTarget: {
                    name: this.props.name,
                    value: selectedOption.value
                }
            });
        }
      };

    public render(){
        return (
            <div className={this.props.formGroupClassName}>
                <div className="form-group">
                    <label>{this.props.labelText}</label>
                    <Select
                        value={this.state.selectedOption}
                        onChange={this.handleChange}
                        options={this.state.options}
                        className="form-control-select" 
                    />
                </div>
            </div>
        );
    }
}