import * as React from 'react';
import './styles/ResultItem.css';

interface IResultItemProps {
    title?: string;
    value?: string;
    className?: string;
    clipboardCopy?: boolean;
}

export class ResultItem extends React.Component<IResultItemProps, any> {
   
    constructor(props: IResultItemProps) {
        
        super(props);
        this.copyToClipboard = this.copyToClipboard.bind(this);
        this.state = {
            clipboardCopy: this.props.clipboardCopy,
            copied: false
        };
    }

    public copyToClipboard = (event:any) => {
        let anyNavigator: any;
        anyNavigator = window.navigator;

        anyNavigator.clipboard.writeText(this.props.value)
        .then(() => {
                /* clipboard successfully set */
            }, () => {
                window.prompt("Press Ctrl+C to copy text", this.props.value);
            })
        .then(() => {
                this.setState({
                    copied: true
                })
            })
        .then(setTimeout(() => { 
                this.setState({
                    copied: false
                }) 
            }, 1000));
        event.stopPropagation();
    }

    public render(){
        const classNameForContainer = this.props.className + " small-body-text result-item";
        return(
            <div className={classNameForContainer}>
                <span className="small-inactive-text result-item-title">{this.props.title}:</span>
                &nbsp;
                {this.props.value}
                &nbsp;
                {            
                    this.state.clipboardCopy &&
                    (this.state.copied 
                        ? <i className="fa fa-check clipboard-copied"/>
                        : <i className="fa fa-copy clipboard-copy" onClick={this.copyToClipboard}/>)
                }
            </div>
        );
    }
}
