// import { DashboardApiMock } from './dashboardApiMock';
// const api = new DashboardApiMock();
import * as actionTypes from './dashboardActionTypes';
import { ICapabilityLinksProps, ICapabilityChartsProps, IChartsFilter } from './dashboardModel';
import { DashboardApiService } from './DashboardApiService';
const api = new DashboardApiService();

export const loadError = () => {
    return { type: actionTypes.OPERATIONAL_LOAD_ERROR, result: { errorLoading: true } };
}

export const loadInProgress = () => {
    return { type: actionTypes.OPERATIONAL_LOAD_IN_PROGRESS };
}

export const loadCapabilityChartsSuccess = (systemCharts: ICapabilityChartsProps) => {
    return { type: actionTypes.DASHBOARD_LOAD_CAPABILITIES_CHARTS_SUCCESS, systemCharts };
}

export const loadCapabilityLinksSuccess = (systemLinks: ICapabilityLinksProps) => {
    return { type: actionTypes.DASHBOARD_LOAD_CAPABILITIES_LINKS_SUCCESS, systemLinks };
}

export const loadCapabilityCharts = (filter: IChartsFilter) => {
    return (dispatch: any) => {
        dispatch(loadInProgress());
        api.getCapabilityCharts(filter)
            .then(items => {
                dispatch(loadCapabilityChartsSuccess(items));
            })
            .catch(error => {
                dispatch(loadError());
            });
    };
}

export const loadCapabilityLinks = () => {
    return (dispatch: any) => {
        dispatch(loadInProgress());
        api.getCapabilitiesLinks()
            .then(items => {
                dispatch(loadCapabilityLinksSuccess(items));
            })
            .catch(error => {
                dispatch(loadError());
            });
    };
}