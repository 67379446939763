import { ApiServiceBase } from "../common/ApiServiceBase";
import { ICapabilityChartsProps, IChartsFilter, ICapabilityLinksProps, ICapabilityProps } from "./dashboardModel";

const GET_CAPABILITIES_URL = '/capabilities';
const GET_STATISTICS_URL = '/statistics';
const GET_CAPABILITY_STATISTICS_URL = '/statistics/capabilities';
const GET_PROCESSES_STATISTICS_URL = '/statistics/processes';
const GET_TOP_FAILURES = '/statistics/top-failures';
const formatDate = "YYYY-MM-DD";
interface ICapabilityStatesDto {
    Completed: number,
    Failed: number,
    New: number,
    Running: number
}
interface ICapabilityStatsDto {
    capability: string,
    name: string,
    stats: ICapabilityStatesDto
}
interface ICapabilityDto {
    name: string,
    processes: string[]
}

export class DashboardApiService extends ApiServiceBase {

    public getCapabilitiesLinks(): Promise<ICapabilityLinksProps> {
        return this.get(GET_CAPABILITIES_URL)
            .then((items: ICapabilityDto[]) => {
                const result: ICapabilityLinksProps = {
                    pageCount: 5,
                    capabilities: []
                };
                if(items){
                    items.forEach((capability: ICapabilityDto) => {
                        const capabilityProp: ICapabilityProps = { id: capability.name, name: capability.name, states: []}
                        result.capabilities.push(capabilityProp);
                    });
                }
                return new Promise<ICapabilityLinksProps>((resolve) => {
                    resolve(result);
                });
        });
    }

    public getCapabilityCharts(filter: IChartsFilter): Promise<ICapabilityChartsProps> {
        const defaultCapability: ICapabilityProps[] = [{ id: "", name:"", states: [] }];
        const defaultCharts: ICapabilityChartsProps = {isShowCapabilityBar: false, capabilities: defaultCapability, allStates: [{name: "Completed", count: 0}, {name: "Running", count: 0}, {name: "Failed", count: 0}]}
        if(filter.isShowCapabilityBar === false){
            return Promise.all([
                this.getTopFailureStatistics(filter),
                this.getAllTypesStatistics(filter)
            ])
            .then(items => {
                const capabilityStats: ICapabilityStatsDto[] = items[0];
                const counter: ICapabilityStatesDto = items[1];
                if(capabilityStats && counter){
                    const topFailureCapabilities: ICapabilityProps[] = [];
                    capabilityStats.forEach((cap: ICapabilityStatsDto) => {
                        const capProps: ICapabilityProps = {
                            id: cap.capability,
                            name: cap.capability,
                            states: [{name: "Failed", count: cap.stats.Failed}]
                        }
                        topFailureCapabilities.push(capProps);
                    });
                    const allCapabilities: ICapabilityChartsProps = {
                        isShowCapabilityBar: filter.isShowCapabilityBar,
                        capabilities: topFailureCapabilities,
                        allStates: [
                            {name: "Completed", count: counter.Completed},
                            {name: "New", count: counter.New},
                            {name: "Failed", count: counter.Failed}]
                    };
                    return new Promise<ICapabilityChartsProps>((resolve) => {
                        resolve(allCapabilities);
                    });
                } else {
                    return new Promise<ICapabilityChartsProps>((resolve) => {
                        resolve(defaultCharts);
                    });
                }
            });
        } else {
            return Promise.all([
                this.getProcessesStatistics(filter),
                this.getCapabilityStatistics(filter)
            ]).then(items => {
                const processesStats: ICapabilityStatsDto[] = items[0];
                const counter: ICapabilityStatsDto = items[1];
                if(processesStats){
                    const capabilities: ICapabilityProps[] = [];
                    const selectedCapability: ICapabilityProps = {
                        id: "",
                        name: "",
                        states: [],
                        processes: []
                    }
                    processesStats.forEach((process: ICapabilityStatsDto) => {
                        if(selectedCapability.processes){
                            selectedCapability.id = process.capability;
                            selectedCapability.name = process.capability;
                            if(process.stats.Failed > 0){
                                selectedCapability.processes.push({
                                    name: process.name,
                                    states: [{name: "Failed", count: process.stats.Failed}]
                                })
                            }
                        }
                    });
                    capabilities.push(selectedCapability);
                    const selectedChartsCapability: ICapabilityChartsProps = {
                        isShowCapabilityBar: filter.isShowCapabilityBar,
                        capabilities,
                        allStates: [
                            {name: "Completed", count: counter.stats.Completed},
                            {name: "New", count: counter.stats.New},
                            {name: "Failed", count: counter.stats.Failed}
                        ]
                    };
                    const processes = selectedChartsCapability.capabilities[0].processes;
                    if(processes){
                        selectedChartsCapability.capabilities[0].processes = processes
                        .filter((keyword, index) => processes.lastIndexOf(keyword) === index)
                        .sort((process1, process2) => process1.states[0].count > process2.states[0].count ? -1 : 1);
                    }
                    
                    return new Promise<ICapabilityChartsProps>((resolve) => {
                        resolve(selectedChartsCapability);
                    });
                }
                else{
                    return new Promise<ICapabilityChartsProps>((resolve) => {
                        resolve(defaultCharts);
                    });
                }
            });
        }
    }

    private getAllTypesStatistics(filter: IChartsFilter): Promise<any> {
        return this.get(GET_STATISTICS_URL, {from: filter.dateFrom.format(formatDate), to: filter.dateTo.format(formatDate)});
    }

    private getTopFailureStatistics(filter: IChartsFilter): Promise<any> {
        return this.get(GET_TOP_FAILURES, {from: filter.dateFrom.format(formatDate), to: filter.dateTo.format(formatDate)});
    }

    private getProcessesStatistics(filter: IChartsFilter): Promise<any> {
        return this.get(GET_PROCESSES_STATISTICS_URL, {capability: filter.selectedCapability, from: filter.dateFrom.format(formatDate), to: filter.dateTo.format(formatDate)});
    }

    private getCapabilityStatistics(filter: IChartsFilter): Promise<any> {
        return this.get(GET_CAPABILITY_STATISTICS_URL, {capability: filter.selectedCapability, from: filter.dateFrom.format(formatDate), to: filter.dateTo.format(formatDate)});
    }
}